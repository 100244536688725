import React from 'react';
import { Box, Skeleton, useMediaQuery } from '@mui/material';

const HeaderSkeleton = () => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: 2,
            }}
        >
            {/* Logo Skeleton */}
            {isSmallScreen ? (
                <Skeleton variant="rectangular" width={200} height={35} />
            ) : (
                <Skeleton variant="rectangular" width={200} height={50} />
            )}

            {/* Menu Items Skeleton */}
            {isSmallScreen ? (
                <Skeleton variant="rectangular" width={50} height={35} />
            ) : (
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Skeleton variant="rectangular" width={80} height={40} />
                    <Skeleton variant="rectangular" width={80} height={40} />
                    <Skeleton variant="rectangular" width={80} height={40} />
                </Box>
            )}
        </Box>
    );
};

export default HeaderSkeleton;
