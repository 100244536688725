import React from 'react';
import { Box, Skeleton, Tabs, Tab } from '@mui/material';

const AccountPlanSekelton = ({ isNested }) => {
  return (
    <Box sx={{ width: '100%', p: !isNested ? '0 16px 16px' : 0 }}>

      <Box
        display="flex"
        justifyContent={!isNested ? 'flex-start' : 'center'}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs value={0}>
          {[1, 2, 3,4,5].map((tab) => (
            <Tab
              key={tab}
              label={<Skeleton variant="text" width={50} height={24} />}
              icon={<Skeleton variant="circular" width={24} height={24} />}
              iconPosition="start"
              sx={{ minHeight: 1, minWidth: '70px' }}
            />
          ))}
        </Tabs>
      </Box>

      {[1, 2, 3].map((subscription) => (
        <Box key={subscription} hidden={true}>
          <Box mt={2}>
            <Skeleton variant="rectangular" width="100%" height={200} />
          </Box>
        </Box>
      ))}
      <Box sx={{display:'flex',flexDirection:'column',gap:2,mt:2}}>
      <Skeleton variant="rectangular" width="100%" height={15}  sx={{borderRadius:5}}/>
      <Skeleton variant="rectangular" width="100%" height={15}  sx={{borderRadius:5}} />
      <Skeleton variant="rectangular" width="100%" height={15}  sx={{borderRadius:5}} />
      <Skeleton variant="rectangular" width="100%" height={15}  sx={{borderRadius:5}} />
      </Box>
      <Box mt={2}>
        <Skeleton variant="rectangular" width="100%" height={200} />
        <Skeleton variant="rectangular" width="100%" height={200} sx={{ mt: 2 }} />
      </Box>
    </Box>
  );
};

export default AccountPlanSekelton;