import { useAuth0 } from '@auth0/auth0-react';

import useAuthCheck from '../../../hooks/useAuthModal';

const useHandleAuthenticatedLink = () => {
    const { isAuthenticated } = useAuth0();
    const checkAuthentication = useAuthCheck();

    const handleClick = (url) => {
        if (isAuthenticated) {
            window.open(url, '_blank', 'noopener,noreferrer');
        } else {
            checkAuthentication();
        }
    };

    return handleClick;
};

export default useHandleAuthenticatedLink;
