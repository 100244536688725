import React from 'react';

import Tooltip from '@mui/material/Tooltip';

/**
 * TooltipWrapper is a component that wraps its children with a tooltip.
 *
 * @param {React.ReactNode} children - The child elements to be wrapped by the tooltip.
 * @param {string} tooltip - The text to be displayed in the tooltip.
 * @param {boolean} [showTooltip=true] - Whether to show the tooltip or not.
 * @returns {JSX.Element} - The rendered component with a tooltip.
 */
const TooltipWrapper = ({
    children,
    tooltip,
    showTooltip = true,
    placement,
}) => {
    if (!showTooltip) {
        return children;
    }
    return (
        <Tooltip
            title={tooltip}
            placement={placement ? placement : 'bottom'}
            leaveDelay={0}
            disableInteractive={true}
        >
            {children}
        </Tooltip>
    );
};

export default TooltipWrapper;
