import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { config } from '../../config/config';
import { checkEnv } from '../../utils/Utils';
import publicAxios from '../../utils/api';
import { logger } from '../../utils/SiteHelpers';

export default function SEO() {
    const [data, setData] = useState(null);
    const location = useLocation();
    const isProduction = checkEnv();

    // Extract dynamic parts of the URL
    const pathSegments = location.pathname.split('/').filter(Boolean); 
    const secondSegment = pathSegments[0] || 'default';
    const thirdSegment = pathSegments[1] || 'default';

    const dynamicApiURL = `https://admin.toolkit.law/api/v1/menu-page-detail/${secondSegment}/${thirdSegment}`;

   

    const fetchSEOdata = useCallback(async () => {
        try {
            const response = await publicAxios.get(dynamicApiURL);
            const responseData = response.data.data;

            

            if (responseData) {
                setData(responseData.page);
            }
        } catch (error) {
            logger.error('Error fetching SEO data:', error);
        }
    }, [dynamicApiURL]);

    useEffect(() => {
        fetchSEOdata();
    }, [fetchSEOdata]);


    if (!data) return null;

    const {
        page_title = "",
        meta_description = "",
        meta_keywords = "",
        slug = "",
        thumbnail = "",
    } = data;
    const { siteUrl, siteName, canonicalUrl } = config;
    const pageURL = `${siteUrl}/${slug}`;
    const pageImage = thumbnail ? `${siteUrl}/${thumbnail}` : `${siteUrl}/default-image.jpg`;

    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: page_title,
        description: meta_description,
        url: pageURL,
        keywords: meta_keywords,
    };

    return (
        <Helmet>
            <title>{page_title}</title>
            <link
                    rel="canonical"
                    href={`${canonicalUrl}${location.pathname}`}
                />
            {isProduction && (
                <>
                    <meta name="description" content={meta_description} />
                    <meta name="keywords" content={meta_keywords} />
                    <meta name="robots" content="index, follow" />
                    <link rel="canonical" href={`${canonicalUrl}${location.pathname}`} />

                    {/* Open Graph (Facebook, LinkedIn) */}
                    <meta property="og:title" content={page_title} />
                    <meta property="og:description" content={meta_description} />
                    <meta property="og:url" content={pageURL} />
                    <meta property="og:image" content={pageImage} />
                    <meta property="og:type" content="website" />

                    {/* Twitter Card */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={page_title} />
                    <meta name="twitter:description" content={meta_description} />
                    <meta name="twitter:image" content={pageImage} />

                    {/* Structured Data (Schema.org) */}
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
                    />
                </>
            )}
        </Helmet>
    );
}
