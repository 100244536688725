export const config = {
    env: process.env.REACT_APP_ENV,
    debug: process.env.REACT_APP_DEBUG,
    siteUrl: process.env.REACT_APP_SITE_URL,
    siteName: process.env.REACT_APP_SITE_NAME,
    canonicalUrl: process.env.REACT_APP_CANONICAL_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    apiKey: process.env.REACT_APP_API_KEY,
    publicFileUri: process.env.REACT_APP_PUBLIC_FILES_URI,
    vendorUrl: process.env.REACT_APP_VENDORS,
    iconUri: process.env.REACT_APP_ICON,
    bannerImage: process.env.REACT_APP_BANNER_IMAGE,
    iconUrl: process.env.REACT_APP_ICON_URL,
    subscriptionUrl: process.env.REACT_APP_SUBSCRIPTION_URL,
    imageUrl: process.env.REACT_APP_IMAGE_URL,
    publicImageUrl: process.env.REACT_APP_PUBLIC_IMAGE_URL,
    publicVideoUrl: process.env.REACT_APP_PUBLIC_VIDEO_URL,
    publicFileUrl: process.env.REACT_APP_PUBLIC_FILE_URL,
    publicPdfUrl: process.env.REACT_APP_PUBLIC_PDF_URL,
    themeIconUrl: process.env.REACT_APP_THEME_ICON_URL,
    toolsUrl: process.env.REACT_APP_TOOLS_URL,
    toolsApiUrl: process.env.REACT_APP_TOOLS_API_URL,
    auth0Url: process.env.REACT_APP_AUTH0_URL,
    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
    auth0RedirectUrl: process.env.REACT_APP_AUTH0_REDIRECT_URL,
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    stripeClientId: process.env.REACT_APP_STRIPE_CLIENT_ID,
    whoIsApiKey: process.env.REACT_APP_WHOIS_API_KEY,
    blogImageUrl: process.env.REACT_APP_BLOG_IMAGE_URL,
    chatterApiUrl:
        process.env.REACT_APP_CHATTER_API_URL || 'http://localhost:5000',
};
