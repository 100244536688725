import { useMemo, useState } from 'react';

import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import { CustomLink } from '../../css/muiStyles/styledComponent';
import { checkEnv } from '../../utils/Utils';
import {
    creative,
    document,
    eventPlanning,
    familyLaw,
    financial,
    lawSchool,
    life,
    litigation,
    marketing,
    mediation,
    office,
    operations,
    suitesSection,
    travel,
} from './schema';
import TooltipWrapper from '../reusable/TooltipWrapper';
import { config } from '../../config/config';

const suiteConfig = {
    office: { name: 'Office', options: office },
    creative: { name: 'Creative', options: creative },
    financial: { name: 'Financial', options: financial },
    familyLaw: { name: 'Family Law', options: familyLaw },
    operations: { name: 'Operations', options: operations },
    travel: { name: 'Travel', options: travel },
    documents: { name: 'Documents', options: document },
    lawSchool: { name: 'Law School', options: lawSchool },
    eventPlanning: { name: 'Event Planning', options: eventPlanning },
    litigation: { name: 'Litigation', options: litigation },
    marketing: { name: 'Marketing', options: marketing },
    mediation: { name: 'Mediation', options: mediation },
    life: { name: 'Life', options: life },
    suitesSection: { name: '', options: suitesSection },
};

const SuitesDropdown = ({ suiteType, buttonStyles, saveRef, submitForm }) => {
    const { siteUrl } = config;
    const isProduction = checkEnv();
    const [dropDownOpen, setDropDownOpen] = useState(null);
    const isOpen = Boolean(dropDownOpen);

    const handleSave = () => {
        const dummyEvent = { preventDefault: () => {} };
        if (saveRef?.current) {
            saveRef.current.handleFormSubmit(dummyEvent);
        } else if (submitForm) {
            submitForm(dummyEvent);
        }
    };

    const handleClick = (event) => {
        handleSave();
        setDropDownOpen(event.currentTarget);
    };

    const handleClose = () => {
        setDropDownOpen(null);
    };

    const { name: suiteName, options: suiteOptions } =
        suiteConfig[suiteType] || suiteConfig.suitesSection;

    const filteredOptions = useMemo(() => {
        return (
            suiteOptions
                ?.filter((item) => !isProduction || item.visibility !== false)
                .sort((a, b) => a.text.localeCompare(b.text)) || []
        );
    }, [suiteOptions, isProduction]);

    return (
        <>
            <TooltipWrapper tooltip="Toolkit.law Suite">
                <IconButton
                    id="dropdown-button"
                    aria-controls={isOpen ? 'dropdown-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={isOpen ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{ fontSize: '0rem', ...buttonStyles }}
                >
                    <Box
                        component={'img'}
                        src="/assets/images/Toolkit.law-logo-icon-combined-black-512.svg"
                        alt="Toolkit.law logo"
                        sx={{
                            width: '24px',
                            height: '24px',
                        }}
                    />
                </IconButton>
            </TooltipWrapper>
            {/* Menu show up on button click */}
            <Menu
                id="dropdown-menu"
                anchorEl={dropDownOpen}
                open={isOpen}
                onClose={() => setDropDownOpen(null)}
            >
                <MenuItem
                    component={CustomLink}
                    href={
                        suiteName === 'Financial'
                            ? `${siteUrl}/suites/financial-suite`
                            : `${siteUrl}/suites/${suiteName
                                  .replace(/\s+/g, '-')
                                  .toLowerCase()}`
                    }
                    sx={{ fontWeight: 'bold' }}
                    onClick={handleClose}
                >
                    <Typography variant="inherit">
                        {`Toolkit.law ${suiteName} ${
                            suiteName ? 'Suite' : 'Suites'
                        }`}
                    </Typography>
                </MenuItem>
                {filteredOptions.map((item) => (
                    <MenuItem
                        key={item.text}
                        component={CustomLink}
                        href={item.url}
                        onClick={handleClose}
                    >
                        {item.text}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default SuitesDropdown;
