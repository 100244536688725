import { useCallback, useContext, useEffect, useState,Suspense } from 'react';

import { Helmet } from 'react-helmet-async';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { Tabs, Tab, Box, Typography, Button } from '@mui/material';

import { CheckCircleRounded } from '@mui/icons-material';

import { AlertMessageContext } from '../../../context/AlertMessageContext';
import { UserSettingsContext } from '../../../context/UserSettingsContext';
import publicAxios, { userAxios } from '../../../utils/api';
import { config } from '../../../config/config';
import { logger } from '../../../utils/SiteHelpers';
import { PlanIds } from '../../../utils/constants';
import { getAuthData } from '../../../utils/auth';
import { checkEnv } from '../../../utils/Utils';
import PlanData from './PlanData';
import LogoutButton from '../../settings/accountInformation/LogoutButton';
import PDFViewer from '../../reusable/PDFViewer';
import MarketingVideo from '../../../pages/about/MarketingVideo';
import AccountTypes from '../../../pages/about/AccountTypes';
import AccountPlanSekelton from '../../skeleton/payment/AccountPlansSkeleton';

const AccountPlans = (props) => {
    const { isNested = false } = props;
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(0);
    const { isAuthenticated, user, loginWithRedirect } = useAuth0();
    const { setErrorMessage } = useContext(AlertMessageContext);
    const { userSettings } = useContext(UserSettingsContext);
    const [subscriptions, setSubscriptions] = useState([]);

    // TODO: Needs to add a loading skeleton
    const [loading, setLoading] = useState(true);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const { subscriptionUrl, siteName, canonicalUrl } = config;
    const isProduction = checkEnv();

    const fetchSubscriptions = useCallback(async () => {
        const apiUrl = `${subscriptionUrl}listing`;

        try {
            const { data, status } = await publicAxios.get(apiUrl);
            const { subscriptions } = data;

            if (status === 200 && subscriptions) {
                const overviewPlan = {
                    id: 0,
                    name: 'Overview',
                    content: <AccountTypes />,
                };

                const updatedSubscriptions = [overviewPlan, ...subscriptions];

                setSubscriptions(updatedSubscriptions);
            } else {
                throw new Error('Failed to fetch subscription data.');
            }
        } catch (error) {
            logger.error('Error fetching subscriptions:', error);
            setErrorMessage(
                'Failed to fetch subscriptions. Please try again later.',
            );
        }
         finally {
            setLoading(false);
        }
    }, [setErrorMessage]);

    const fetchSubscriptionAccess = useCallback(async () => {
        try {
            const { email, sub: auth0id } = user;
            const { accessToken, userId } = await getAuthData(email, auth0id);

            if (!accessToken) {
                throw new Error('No access token available.');
            }

            const apiUrl = `${subscriptionUrl}access`;
            const payload = { user_id: userId };
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            const { data } = await userAxios.post(apiUrl, payload, config);

            if (data?.data?.[0]?.subscription_id) {
                setSubscriptionId(data.data[0].subscription_id);
            }
        } catch (error) {
            logger.error('Error fetching subscription access:', error);
        }
    }, [user]);

    useEffect(() => {
        const loadData = async () => {
            if (isAuthenticated) {
                await fetchSubscriptionAccess();
            }
            await fetchSubscriptions();
         
        };
        loadData();
    }, [isAuthenticated, fetchSubscriptions, fetchSubscriptionAccess]);

    const isTrialExpired = (subscriptionId) => {
        return subscriptionId === PlanIds.TRIAL && userSettings.trial === '1';
    };

    const isCurrentSubscription = (currentId) => {
        return subscriptionId === currentId;
    };

    const getTabLabel = (planId, planName) => {
        const planIdMapping = {
            [PlanIds.SPECIAL]: 'Special',
            [PlanIds.TRIAL]: 'Free',
            [PlanIds.MONTHLY]: 'Monthly',
            [PlanIds.ANNUAL]: 'Annual',
        };

        return planIdMapping[planId] || planName;
    };

    const a11yProps = (index) => ({
        id: `plan-tab-${index}`,
        'aria-controls': `plan-tabpanel-${index}`,
    });

    const preferredPlansOrder = [
        PlanIds.TRIAL,
        PlanIds.MONTHLY,
        PlanIds.ANNUAL,
        PlanIds.SPECIAL,
    ];

    const sortedSubscriptions = subscriptions.sort(
        (a, b) =>
            preferredPlansOrder.indexOf(a.id) -
            preferredPlansOrder.indexOf(b.id),
    );

    return (
        <>
            {!isNested && (
                <Helmet>
                    <title>{`Pricing | ${siteName}`}</title>
                    <link
                        rel="canonical"
                        href={`${canonicalUrl}${location.pathname}`}
                    />
                    {isProduction && (
                        <>
                            <meta
                                name="description"
                                content="Toolkit.law Billing Plans, Legal Software Subscription Options, Toolkit.law Pricing Plans, Affordable Legal Software Pricing, Subscription Plans for Legal Professionals, Toolkit.law Payment Options, Legal Service Plans, Monthly Billing Plans for Law Firms, Annual Legal Software Plans, Toolkit.law Features and Pricing, Legal Technology Subscription Rates, Compare Legal Software Pricing, Toolkit.law Plan Details, Flexible Billing Plans for Lawyers, Toolkit.law Membership Options, Cost-Effective Legal Solutions, Toolkit.law Plan Benefits, Legal Software Payment Plans, Toolkit.law Subscription Tiers, Budget-Friendly Legal Software Options, Legal Services Pricing Guide, Toolkit.law Payment Plans Overview, Legal Software Pricing Comparison, Comprehensive Legal Billing Solutions, Toolkit.law Subscription Benefits"
                            />
                            <meta
                                name="keywords"
                                content="Toolkit.law Billing Plans, Legal Software Subscription Options, Toolkit.law Pricing Plans, Affordable Legal Software Pricing, Subscription Plans for Legal Professionals, Toolkit.law Payment Options, Legal Service Plans, Monthly Billing Plans for Law Firms, Annual Legal Software Plans, Toolkit.law Features and Pricing, Legal Technology Subscription Rates, Compare Legal Software Pricing, Toolkit.law Plan Details, Flexible Billing Plans for Lawyers, Toolkit.law Membership Options, Cost-Effective Legal Solutions, Toolkit.law Plan Benefits, Legal Software Payment Plans, Toolkit.law Subscription Tiers, Budget-Friendly Legal Software Options, Legal Services Pricing Guide, Toolkit.law Payment Plans Overview, Legal Software Pricing Comparison, Comprehensive Legal Billing Solutions, Toolkit.law Subscription Benefits"
                         
                         />
                        
                        </>
                    )}
                </Helmet>
            )}
            {loading ? <AccountPlanSekelton/> : (
            <Box sx={{ width: '100%', p: !isNested ? '0 16px 16px' : 0 }}>
                {!isNested && (
                    <Box
                        position="relative"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                    >
                        <Typography
                            variant="h4"
                            component="h3"
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            Pricing
                        </Typography>
                        <Box ml="auto">
                            {isAuthenticated ? (
                                <LogoutButton />
                            ) : (
                                <Button onClick={loginWithRedirect}>
                                    Login
                                </Button>
                            )}
                        </Box>
                    </Box>
                )}
                <Box
                    display="flex"
                    justifyContent={!isNested ? 'center' : 'flex-start'}
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                    <Tabs
                        value={selectedTab}
                        onChange={(_, newValue) => setSelectedTab(newValue)}
                    >
                        {sortedSubscriptions.map((subscription) => (
                            <Tab
                                key={subscription.id}
                                label={getTabLabel(
                                    subscription.id,
                                    subscription.name,
                                )}
                                icon={
                                    isCurrentSubscription(subscription.id) ? (
                                        <CheckCircleRounded color="primary" />
                                    ) : null
                                }
                                iconPosition="start"
                                sx={{ minHeight: 1, minWidth: '70px' }}
                                {...a11yProps(subscription.id)}
                            />
                        ))}
                    </Tabs>
                </Box>
                {sortedSubscriptions.map((subscription, index) => (
                    <Box key={subscription.id} hidden={selectedTab !== index}>
                        <PlanData
                            subscription={subscription}
                            isTrialExpired={isTrialExpired(subscription.id)}
                            isNested={isNested}
                            isCurrentSubscription={isCurrentSubscription(
                                subscription.id,
                            )}
                            currentSubscriptionId={subscriptionId}
                        />
                    </Box>
                ))}
                {sortedSubscriptions[selectedTab] &&
                    !sortedSubscriptions[selectedTab].content && (
                        <Box mt={2}>
                            <MarketingVideo
                                videoUrl="toolkitlaw-account-plans-flyer-video.mp4"
                                thumbnailUrl="video-thumbnail-account-plans.jpg"
                            />
                            <PDFViewer fileUrl="toolkitlaw-account-plans-flyer.pdf" />
                        </Box>
                    )}
            </Box>
            )}
                
        </>
    );
};

export default AccountPlans;
