import React, { createContext, useState, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { getAuthData } from '../utils/auth';
import { userAxios } from '../utils/api';

// Create the context
export const BlogContext = createContext();

// Blog Provider component to wrap the app
export const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);
    const [categories, setCategories] = useState(['All']);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isloading, setIsloading] = useState(true);
    const { user } = useAuth0();

    const fetchBlogs = async (page = 1) => {
        if (!user) {
            setIsloading(false);
            return;
        }
        const { email, sub: auth0id } = user;
        const authData = await getAuthData(email, auth0id);
        const { accessToken } = authData;

        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        };

        const apiUrl = `blogs-listing?page=${page}`;
        try {
            const response = await userAxios.get(apiUrl, config);
            const { data } = response.data;
            if (data.data) {
                // Update blogs, total pages, and pagination info
                setBlogs(data.data);
                setCurrentPage(data.current_page);
                setTotalPages(data.last_page);

                // Extract and set categories
                const categoryList = [
                    'All',
                    ...new Set(
                        data.data.flatMap((blog) => blog.category?.name),
                    ),
                ];
                setCategories(categoryList);
            }
        } catch (error) {
            console.error('Error fetching blogs:', error);
        } finally {
            setIsloading(false);
        }
    };
    // Fetch blogs and categories from the API
    useEffect(() => {
        fetchBlogs(currentPage);
    }, [currentPage]);

    return (
        <BlogContext.Provider
            value={{
                blogs,
                categories,
                selectedCategory,
                currentPage,
                totalPages,
                setBlogs,
                setCategories,
                setSelectedCategory,
                setCurrentPage,
                setTotalPages,
                fetchBlogs,
                isloading,
            }}
        >
            {children}
        </BlogContext.Provider>
    );
};
