import { config } from '../../config/config';
import { webstoreAccordionName } from '../../pages/settings/schema';

const { siteUrl, themeIconUrl, imageUrl } = config;

export const travel = [
    {
        icon: `${themeIconUrl}icon_1725359012.svg`,
        url: `${siteUrl}/tools/budgeter`,
        text: 'Budgeter',
    },
    {
        icon: `${themeIconUrl}icon_1725361293.svg`,
        url: `${siteUrl}/tools/vehicle-manager`,
        text: 'Vehicle Manager',
    },
    {
        icon: `${themeIconUrl}icon_1725360808.svg`,
        url: `${siteUrl}/tools/planner`,
        text: 'Planner',
    },
    {
        icon: `${themeIconUrl}icon_1725364814.svg`,
        url: `${siteUrl}/numbers/time`,
        text: 'Time',
    },
    {
        icon: `${themeIconUrl}icon_1725364847.svg`,
        url: `${siteUrl}/numbers/transportation`,
        text: 'Transportation',
    },
    {
        icon: `${themeIconUrl}icon_1725361982.svg`,
        url: `${siteUrl}/tools/tripper`,
        text: 'Tripper',
    },
    {
        icon: `${themeIconUrl}icon_1725365866.svg`,
        url: `${siteUrl}/self/traveler`,
        text: 'Traveler',
    },
    {
        icon: `${themeIconUrl}icon_1725365908.svg`,
        url: `${siteUrl}/self/vehicular`,
        text: 'Vehicular',
    },
    {
        icon: `${themeIconUrl}icon_1725365949.svg`,
        url: `${siteUrl}/self/weather`,
        text: 'Weather',
    },
];

export const creative = [
    {
        icon: `${themeIconUrl}icon_1725358788.svg`,
        url: `${siteUrl}/tools/audio-editor`,
        text: 'Audio Editor',
    },
    {
        icon: `${themeIconUrl}icon_1725359338.svg`,
        url: `${siteUrl}/tools/charter`,
        text: 'Charter',
    },
    {
        icon: `${themeIconUrl}icon_1725359571.svg`,
        url: `${siteUrl}/tools/converter`,
        text: 'Converter',
    },
    {
        icon: `${themeIconUrl}icon_1725363471.svg`,
        url: `${siteUrl}/numbers/graphics`,
        text: 'Graphics',
    },
    {
        icon: `${themeIconUrl}icon_1725360047.svg`,
        url: `${siteUrl}/tools/imager`,
        text: 'Imager',
    },
    {
        icon: `${themeIconUrl}icon_1725360768.svg`,
        url: `${siteUrl}/tools/pdf-editor`,
        text: 'PDF Editor',
    },
    {
        icon: `${themeIconUrl}icon_1725360853.svg`,
        url: `${siteUrl}/tools/presenter`,
        text: 'Presenter',
    },
    {
        icon: `${themeIconUrl}icon_1725361044.svg`,
        url: `${siteUrl}/tools/qr-coder`,
        text: 'QR Coder',
    },
    {
        icon: `${themeIconUrl}icon_1725361338.svg`,
        url: `${siteUrl}/tools/video-editor`,
        text: 'Video Editor',
    },
    {
        icon: `${themeIconUrl}icon_1725362073.svg`,
        url: `${siteUrl}/tools/viewer`,
        text: 'Viewer',
    },
    {
        icon: `${themeIconUrl}icon_1730095122.png`,
        url: `${siteUrl}/tools/publisher`,
        text: 'Publisher',
    },
];

export const document = [
    {
        icon: `${themeIconUrl}icon_1725362211.svg`,
        url: `${siteUrl}/numbers/assets`,
        text: 'Assets',
    },
    {
        icon: `${themeIconUrl}icon_1725359916.svg`,
        url: `${siteUrl}/tools/filer`,
        text: 'Filer',
    },
    {
        icon: `${themeIconUrl}icon_1725363847.svg`,
        url: `${siteUrl}/numbers/legal`,
        text: 'Legal Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725363896.svg`,
        url: `${siteUrl}/numbers/liabilities`,
        text: 'Liabilities',
    },
    {
        icon: `${themeIconUrl}icon_1725363942.svg`,
        url: `${siteUrl}/numbers/lifecycle`,
        text: 'Lifecycle',
    },
    {
        icon: `${themeIconUrl}icon_1725362042.svg`,
        url: `${siteUrl}/tools/notetaker`,
        text: 'Notetaker',
    },
    {
        icon: `${themeIconUrl}icon_1725360768.svg`,
        url: `${siteUrl}/tools/pdf-editor`,
        text: 'PDF Editor',
    },
    {
        icon: `${themeIconUrl}icon_1725361165.svg`,
        url: `${siteUrl}/tools/signer`,
        text: 'Signer',
    },
    {
        icon: `${themeIconUrl}icon_1725358937.svg`,
        url: `${siteUrl}/tools/bates-number`,
        text: 'Bates Number',
    },
    {
        icon: `${themeIconUrl}icon_1725361115.svg`,
        url: `${siteUrl}/tools/scanner`,
        text: 'Scanner',
    },
    {
        icon: `${themeIconUrl}icon_1725359571.svg`,
        url: `${siteUrl}/tools/converter`,
        text: 'Converter',
    },
    {
        icon: `${themeIconUrl}icon_1725366282.svg`,
        url: `${siteUrl}/tools/writer`,
        text: 'Writer',
    },
    {
        icon: `${themeIconUrl}icon_1725362073.svg`,
        url: `${siteUrl}/tools/viewer`,
        text: 'Viewer',
    },
];

export const lawSchool = [
    {
        icon: `${themeIconUrl}icon_1725359383.svg`,
        url: `${siteUrl}/tools/chatter`,
        text: 'Chatter',
    },
    {
        icon: `${themeIconUrl}icon_1725363847.svg`,
        url: `${siteUrl}/numbers/legal`,
        text: 'Legal Calculators',
    },

    {
        icon: `${themeIconUrl}icon_1725365596.svg`,
        url: `${siteUrl}/self/monetizer`,
        text: 'Monetizer',
    },
    {
        icon: `${themeIconUrl}icon_1725362042.svg`,
        url: `${siteUrl}/tools/notetaker`,
        text: 'Notetaker',
    },
    {
        icon: `${themeIconUrl}icon_1725364991.svg`,
        url: `${siteUrl}/numbers/school`,
        text: 'School',
    },
    {
        icon: `${themeIconUrl}icon_1725365656.svg`,
        url: `${siteUrl}/self/semester`,
        text: 'Semester',
    },

    {
        icon: `${themeIconUrl}icon_1725366282.svg`,
        url: `${siteUrl}/tools/writer`,
        text: 'Writer',
    },
    {
        icon: `${themeIconUrl}icon_1725365198.svg`,
        url: `${siteUrl}/self/career`,
        text: 'Career',
    },
    {
        icon: `${themeIconUrl}icon_1725360079.svg`,
        url: `${siteUrl}/tools/improver`,
        text: 'Improver',
    },
    {
        icon: `${themeIconUrl}icon_1725366464.svg`,
        url: `${siteUrl}/tools/organizer`,
        text: 'Organizer',
    },
];

export const litigation = [
    {
        icon: `${themeIconUrl}icon_1725360475.svg`,
        url: `${siteUrl}/tools/mediator`,
        text: 'Mediator',
    },
    {
        icon: `${themeIconUrl}icon_1725358937.svg`,
        url: `${siteUrl}/tools/bates-number`,
        text: 'Bates Number',
    },
    {
        icon: `${themeIconUrl}icon_1725359648.svg`,
        url: `${siteUrl}/tools/discover`,
        text: 'Discover',
    },
    {
        icon: `${themeIconUrl}icon_1725359682.svg`,
        url: `${siteUrl}/tools/docketer`,
        text: 'Docketer',
    },
    {
        icon: `${themeIconUrl}icon_1725359952.svg`,
        url: `${siteUrl}/tools/hearing-manager`,
        text: 'Hearing Manager',
    },
    {
        icon: `${themeIconUrl}icon_1725360131.svg`,
        url: `${siteUrl}/tools/intaker`,
        text: 'Intaker',
    },
    {
        icon: `${themeIconUrl}icon_1725359952.svg`,
        url: `${siteUrl}/tools/juror`,
        text: 'Juror',
    },

    {
        icon: `${themeIconUrl}icon_1725360348.svg`,
        url: `${siteUrl}/tools/litigator`,
        text: 'Litigator',
    },
    {
        icon: `${themeIconUrl}icon_1725360437.svg`,
        url: `${siteUrl}/tools/matter`,
        text: 'Matter',
    },

    {
        icon: `${themeIconUrl}icon_1725360768.svg`,
        url: `${siteUrl}/tools/pdf-editor`,
        text: 'PDF Editor',
    },
    // {
    //     icon: `${themeIconUrl}icon_1725361081.svg`,
    //     url: `${siteUrl}/tools/researcher`,
    //     text: 'Researcher',
    //     visibility: false,
    // },
    {
        icon: `${themeIconUrl}icon_1725364814.svg`,
        url: `${siteUrl}/numbers/time`,
        text: 'Time Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725359916.svg`,
        url: `${siteUrl}/tools/filer`,
        text: 'Filer',
    },
    {
        icon: `${themeIconUrl}icon_1725360175.svg`,
        url: `${siteUrl}/tools/invoicer`,
        text: 'Invoicer',
    },
    {
        icon: `${themeIconUrl}icon_1725363847.svg`,
        url: `${siteUrl}/numbers/legal`,
        text: 'Legal Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725361165.svg`,
        url: `${siteUrl}/tools/signer`,
        text: 'Signer',
    },
    {
        icon: `${themeIconUrl}icon_1725362042.svg`,
        url: `${siteUrl}/tools/tester`,
        text: 'Tester',
    },
    {
        icon: `${themeIconUrl}icon_1725358909.svg`,
        url: `${siteUrl}/tools/backgrounder`,
        text: 'Backgrounder',
    },
    {
        icon: `${themeIconUrl}icon_1729960681.svg`,
        url: `${siteUrl}/tools/divorcer`,
        text: 'Divorcer',
    },
    {
        icon: `${themeIconUrl}icon_1735487639.svg`,
        url: `${siteUrl}/tools/evidencer`,
        text: 'Evidencer',
    },
];

export const marketing = [
    {
        icon: `${themeIconUrl}icon_1725359338.svg`,
        url: `${siteUrl}/tools/charter`,
        text: 'Charter',
    },
    {
        icon: `${themeIconUrl}icon_1725359419.svg`,
        url: ` ${siteUrl}/tools/communicator`,
        text: 'Communicator',
    },
    {
        icon: `${themeIconUrl}icon_1725363471.svg`,
        url: `${siteUrl}/numbers/graphics`,
        text: 'Graphics',
    },
    {
        icon: `${themeIconUrl}icon_1725360047.svg`,
        url: `${siteUrl}/tools/imager`,
        text: 'Imager',
    },
    {
        icon: `${themeIconUrl}icon_1725360389.svg`,
        url: `${siteUrl}/tools/marketer`,
        text: 'Marketer',
    },
    {
        icon: `${themeIconUrl}icon_1725360560.svg`,
        url: `${siteUrl}/tools/newsletter`,
        text: 'Newsletter',
    },
    {
        icon: `${imageUrl}1721636525.png`,
        url: `${siteUrl}/settings?accordion=${webstoreAccordionName}`,
        text: 'User Webstore',
    },
    {
        icon: `${themeIconUrl}icon_1725361044.svg`,
        url: `${siteUrl}/tools/qr-coder`,
        text: 'QR Coder',
    },
    {
        icon: `${themeIconUrl}icon_1725365691.svg`,
        url: `${siteUrl}/self/socializer.`,
        text: 'Socializer',
    },
    {
        icon: `${themeIconUrl}icon_1730095122.png`,
        url: `${siteUrl}/tools/publisher`,
        text: 'Publisher',
    },
];

export const operations = [
    {
        icon: `${themeIconUrl}icon_1725359012.svg`,
        url: `${siteUrl}/tools/budgeter`,
        text: 'Budgeter',
    },
    {
        icon: `${themeIconUrl}icon_1725362917.svg`,
        url: `${siteUrl}/numbers/business`,
        text: 'Business Calculator',
    },
    {
        icon: `${themeIconUrl}icon_1725359383.svg`,
        url: `${siteUrl}/tools/chatter`,
        text: 'Chatter',
    },
    {
        icon: `${themeIconUrl}icon_1725361293.svg`,
        url: `${siteUrl}/tools/vehicle-manager`,
        text: 'Vehicle Manager',
    },
    {
        icon: `${themeIconUrl}icon_1725359999.svg`,
        url: `${siteUrl}/tools/hr-manager`,
        text: 'HR Manager',
    },
    {
        icon: `${themeIconUrl}icon_1725360240.svg`,
        url: `${siteUrl}/tools/it-manager`,
        text: 'IT Manager',
    },
    {
        icon: `${themeIconUrl}icon_1725360727.svg`,
        url: `${siteUrl}/tools/payor`,
        text: 'Payor',
    },
    {
        icon: `${themeIconUrl}icon_1725364752.svg`,
        url: `${siteUrl}/numbers/technology`,
        text: 'Technology ',
    },
    {
        icon: `${themeIconUrl}icon_1725365908.svg`,
        url: `${siteUrl}/self/vehicular`,
        text: 'Vehicular',
    },
    {
        icon: `${themeIconUrl}icon_1725359291.svg`,
        url: `${siteUrl}/tools/caller`,
        text: 'Caller',
    },
    {
        icon: `${themeIconUrl}icon_1725360808.svg`,
        url: `${siteUrl}/tools/planner`,
        text: 'Planner',
    },
    {
        icon: `${themeIconUrl}icon_1725360964.svg`,
        url: `${siteUrl}/tools/protector`,
        text: 'Protector',
    },
    {
        icon: `${themeIconUrl}icon_1725363262.svg`,
        url: `${siteUrl}/numbers/calculators`,
        text: 'Basic Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725364388.svg`,
        url: `${siteUrl}/numbers/sales`,
        text: 'Sales',
    },
    {
        icon: `${themeIconUrl}icon_1725364847.svg`,
        url: `${siteUrl}/numbers/transportation`,
        text: 'Transportation',
    },
    {
        icon: `${themeIconUrl}icon_1730793835.svg`,
        url: `${siteUrl}/tools/inviter`,
        text: 'Inviter',
    },
];

export const financial = [
    {
        icon: `${themeIconUrl}icon_1725359012.svg`,
        url: `${siteUrl}/tools/budgeter`,
        text: 'Budgeter',
    },
    {
        icon: `${themeIconUrl}icon_1725363262.svg`,
        url: `${siteUrl}/numbers/calculators`,
        text: 'Basic Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725363376.svg`,
        url: `${siteUrl}/numbers/finance`,
        text: 'Finance',
    },
    {
        icon: `${themeIconUrl}icon_1725365418.svg`,
        url: `${siteUrl}/self/homemaker`,
        text: 'Homemaker',
    },
    {
        icon: `${themeIconUrl}icon_1725363549.svg`,
        url: `${siteUrl}/numbers/income`,
        text: 'Income Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725363584.svg`,
        url: `${siteUrl}/numbers/interest`,
        text: 'Interest',
    },
    {
        icon: `${themeIconUrl}icon_1725363819.svg`,
        url: `${siteUrl}/numbers/investment`,
        text: 'Investment',
    },
    {
        icon: `${themeIconUrl}icon_1725360175.svg`,
        url: `${siteUrl}/tools/invoicer`,
        text: 'Invoicer',
    },
    {
        icon: `${themeIconUrl}icon_1725365596.svg`,
        url: `${siteUrl}/self/monetizer`,
        text: 'Monetizer',
    },
    {
        icon: `${themeIconUrl}icon_1725360727.svg`,
        url: `${siteUrl}/tools/payor`,
        text: 'Payor',
    },
    {
        icon: `${themeIconUrl}icon_1725365793.svg`,
        url: `${siteUrl}/self/taxer`,
        text: 'Taxer',
    },
    {
        icon: `${themeIconUrl}icon_1725362917.svg`,
        url: `${siteUrl}/numbers/business`,
        text: 'Business Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725364221.svg`,
        url: `${siteUrl}/numbers/percentage`,
        text: 'Percentage Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1727853517.svg`,
        url: `${siteUrl}/numbers/banker`,
        text: 'Banker',
    },
    {
        icon: `${themeIconUrl}icon_1729960196.svg`,
        url: `${siteUrl}/self/investor`,
        text: 'Investor',
    },
];

export const life = [
    {
        icon: `${themeIconUrl}icon_1725365198.svg`,
        url: `${siteUrl}/self/career`,
        text: 'Career',
    },
    {
        icon: `${themeIconUrl}icon_1725359291.svg`,
        url: `${siteUrl}/tools/caller`,
        text: 'Caller',
    },
    {
        icon: `${themeIconUrl}icon_1725363334.svg`,
        url: `${siteUrl}/numbers/cooking`,
        text: 'Cooking',
    },
    {
        icon: `${themeIconUrl}icon_1725365234.svg`,
        url: `${siteUrl}/self/doctor`,
        text: 'Doctor',
    },
    {
        icon: `${themeIconUrl}icon_1725363503.svg`,
        url: `${siteUrl}/numbers/health`,
        text: 'Health',
    },
    {
        icon: `${themeIconUrl}icon_1725363549.svg`,
        url: `${siteUrl}/numbers/income`,
        text: 'Income Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725360079.svg`,
        url: `${siteUrl}/tools/improver`,
        text: 'Improver',
    },
    {
        icon: `${themeIconUrl}icon_1725363942.svg`,
        url: `${siteUrl}/numbers/lifecycle`,
        text: 'Lifecycle',
    },
    {
        icon: `${themeIconUrl}icon_1725360727.svg`,
        url: `${siteUrl}/tools/payor`,
        text: 'Payor',
    },
    {
        icon: `${themeIconUrl}icon_1725364317.svg`,
        url: `${siteUrl}/numbers/retire`,
        text: 'Retire',
    },
    {
        icon: `${themeIconUrl}icon_1725365627.svg`,
        url: `${siteUrl}/self/romancer`,
        text: 'Romancer',
    },
    {
        icon: `${themeIconUrl}icon_1725365793.svg`,
        url: `${siteUrl}/self/taxer`,
        text: 'Taxer',
    },
    {
        icon: `${themeIconUrl}icon_1725364622.svg`,
        url: `${siteUrl}/numbers/shopping`,
        text: 'Shopping',
    },
    {
        icon: `${themeIconUrl}icon_1725366910.svg`,
        url: `${siteUrl}/numbers/military`,
        text: 'Military Calculators',
    },
];

export const office = [
    {
        icon: `${themeIconUrl}icon_1725359241.svg`,
        url: `${siteUrl}/tools/calendar`,
        text: 'Calendar',
    },
    {
        icon: `${themeIconUrl}icon_1725359338.svg`,
        url: `${siteUrl}/tools/charter`,
        text: 'Charter',
    },
    {
        icon: `${themeIconUrl}icon_1725359383.svg`,
        url: `${siteUrl}/tools/chatter`,
        text: 'Chatter',
    },
    {
        icon: `${themeIconUrl}icon_1725359419.svg`,
        url: `${siteUrl}/tools/communicator`,
        text: 'Communicator',
    },
    {
        icon: `${themeIconUrl}icon_1725359541.svg`,
        url: `${siteUrl}/tools/contactor`,
        text: 'Contactor',
    },
    {
        icon: `${themeIconUrl}icon_1725359571.svg`,
        url: `${siteUrl}/tools/converter`,
        text: 'Converter',
    },
    {
        icon: `${themeIconUrl}icon_1725360853.svg`,
        url: `${siteUrl}/tools/presenter`,
        text: 'Presenter',
    },
    {
        icon: `${themeIconUrl}icon_1725362042.svg`,
        url: `${siteUrl}/tools/notetaker`,
        text: 'Notetaker',
    },
    {
        icon: `${themeIconUrl}icon_1725448366.svg`,
        url: `${siteUrl}/tools/spreadsheeter`,
        text: 'Spreadsheeter',
    },
    {
        icon: `${themeIconUrl}icon_1725366282.svg`,
        url: `${siteUrl}/tools/writer`,
        text: 'Writer',
    },
    {
        icon: `${themeIconUrl}icon_1725362073.svg`,
        url: `${siteUrl}/tools/viewer`,
        text: 'Viewer',
    },
    {
        icon: `${themeIconUrl}icon_1725366464.svg`,
        url: `${siteUrl}/tools/organizer`,
        text: 'Organizer',
    },
    {
        icon: `${themeIconUrl}icon_1730095122.png`,
        url: `${siteUrl}/tools/publisher`,
        text: 'Publisher',
    },
];

export const suitesSection = [
    {
        url: `${siteUrl}/suites/creative`,
        text: 'Creative Suite',
    },
    {
        url: `${siteUrl}/suites/documents`,
        text: 'Documents Suite',
    },
    {
        url: `${siteUrl}/suites/law-school`,
        text: 'Law School Suite',
    },
    {
        url: `${siteUrl}/suites/financial-suite`,
        text: 'Financial Suite',
    },
    {
        url: `${siteUrl}/suites/life`,
        text: 'Life Suite',
    },
    {
        url: `${siteUrl}/suites/litigation`,
        text: 'Litigation Suite',
    },
    {
        url: `${siteUrl}/suites/marketing`,
        text: 'Marketing Suite',
    },
    {
        url: `${siteUrl}/suites/office`,
        text: 'Office Suite',
    },
    {
        url: `${siteUrl}/suites/operations`,
        text: 'Operations Suite',
    },
    {
        url: `${siteUrl}/suites/travel`,
        text: 'Travel Suite',
    },
    {
        url: `${siteUrl}/suites/mediation`,
        text: 'Mediation Suite',
    },
    {
        url: `${siteUrl}/suites/event-planning`,
        text: 'Event Planning Suite',
    },
    {
        url: `${siteUrl}/suites/family-law`,
        text: 'Family Law Suite',
    },
];

export const mediation = [
    {
        icon: `${themeIconUrl}icon_1725360475.svg`,
        url: `${siteUrl}/tools/mediator`,
        text: 'Mediator',
    },
    {
        icon: `${themeIconUrl}icon_1725362211.svg`,
        url: `${siteUrl}/numbers/assets`,
        text: 'Assets',
    },
    {
        icon: `${themeIconUrl}icon_1725362917.svg`,
        url: `${siteUrl}/numbers/calculators`,
        text: 'Basic Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725359498.svg`,
        url: `${siteUrl}tools/conferencer`,
        text: 'Conferencer',
    },
    {
        icon: `${themeIconUrl}icon_1725359916.svg`,
        url: `${siteUrl}/tools/filer`,
        text: 'Filer',
    },
    {
        icon: `${themeIconUrl}icon_1725363549.svg`,
        url: `${siteUrl}/numbers/income`,
        text: 'Income Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725363847.svg`,
        url: `${siteUrl}/numbers/legal`,
        text: 'Legal Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725363896.svg`,
        url: `${siteUrl}/numbers/liabilities`,
        text: 'Liabilities',
    },
    {
        icon: `${themeIconUrl}icon_1725360727.svg`,
        url: `${siteUrl}/tools/payor`,
        text: 'Payor',
    },
    {
        icon: `${themeIconUrl}icon_1725364266.svg`,
        url: `${siteUrl}/numbers/realty`,
        text: 'Realty Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725361165.svg`,
        url: `${siteUrl}/tools/signer`,
        text: 'Signer',
    },
    {
        icon: `${themeIconUrl}icon_1725366282.svg`,
        url: `${siteUrl}/tools/writer`,
        text: 'Writer',
    },
    {
        icon: `${themeIconUrl}icon_1729960681.svg`,
        url: `${siteUrl}/tools/divorcer`,
        text: 'Divorcer',
    },
];
export const eventPlanning = [
    {
        icon: `${themeIconUrl}icon_1725359012.svg`,
        url: `${siteUrl}/tools/budgeter`,
        text: 'Budgeter',
    },
    {
        icon: `${themeIconUrl}icon_1725363262.svg`,
        url: `${siteUrl}/numbers/calculators`,
        text: 'Basic Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725359241.svg`,
        url: `${siteUrl}/tools/calendar`,
        text: 'Calendar',
    },
    {
        icon: `${themeIconUrl}icon_1725359338.svg`,
        url: `${siteUrl}/tools/charter`,
        text: 'Charter',
    },
    {
        icon: `${themeIconUrl}icon_1725359854.svg`,
        url: `${siteUrl}/tools/eventer`,
        text: 'Eventer',
    },
    {
        icon: `${themeIconUrl}icon_1725360389.svg`,
        url: `${siteUrl}/tools/marketer`,
        text: 'Marketer',
    },
    {
        icon: `${themeIconUrl}icon_1725360808.svg`,
        url: `${siteUrl}/tools/planner`,
        text: 'Planner',
    },
    {
        icon: `${themeIconUrl}icon_1725360853.svg`,
        url: `${siteUrl}/tools/presenter`,
        text: 'Presenter',
    },
    {
        icon: `${themeIconUrl}icon_1725360853.svg`,
        url: `${siteUrl}/numbers/lifecycle`,
        text: 'Lifecycle',
    },
];

export const familyLaw = [
    {
        icon: `${themeIconUrl}icon_1725360475.svg`,
        url: `${siteUrl}/tools/mediator`,
        text: 'Mediator',
    },
    {
        icon: `${themeIconUrl}icon_1725362211.svg`,
        url: `${siteUrl}/numbers/assets`,
        text: 'Assets',
    },
    {
        icon: `${themeIconUrl}icon_1725363376.svg`,
        url: `${siteUrl}/numbers/finance`,
        text: 'Finance',
    },
    {
        icon: `${themeIconUrl}icon_1725359952.svg`,
        url: `${siteUrl}/tools/hearing-manager`,
        text: 'Hearing Manager',
    },
    {
        icon: `${themeIconUrl}icon_1725365418.svg`,
        url: `${siteUrl}/self/homemaker`,
        text: 'Homemaker',
    },
    {
        icon: `${themeIconUrl}icon_1725360079.svg`,
        url: `${siteUrl}/tools/improver`,
        text: 'Improver',
    },
    {
        icon: `${themeIconUrl}icon_1725363549.svg`,
        url: `${siteUrl}/numbers/income`,
        text: 'Income Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725363847.svg`,
        url: `${siteUrl}/numbers/legal`,
        text: 'Legal Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725363896.svg`,
        url: `${siteUrl}/numbers/liabilities`,
        text: 'Liabilities',
    },
    {
        icon: `${themeIconUrl}icon_1725360348.svg`,
        url: `${siteUrl}/tools/litigator`,
        text: 'Litigator',
    },
    {
        icon: `${themeIconUrl}icon_1725360437.svg`,
        url: `${siteUrl}/tools/matter`,
        text: 'Matter',
    },
    {
        icon: `${themeIconUrl}icon_1725365596.svg`,
        url: `${siteUrl}/self/monetizer`,
        text: 'Monetizer',
    },
    {
        icon: `${themeIconUrl}icon_1725364266.svg`,
        url: `${siteUrl}/numbers/realty`,
        text: 'Realty Calculators',
    },
    {
        icon: `${themeIconUrl}icon_1725365793.svg`,
        url: `${siteUrl}/self/taxer`,
        text: 'Taxer',
    },
    {
        icon: `${themeIconUrl}icon_1725365908.svg`,
        url: `${siteUrl}/self/vehicular`,
        text: 'Vehicular',
    },
    {
        icon: `${themeIconUrl}icon_1729960196.svg`,
        url: `${siteUrl}/self/investor`,
        text: 'Investor',
    },
    {
        icon: `${themeIconUrl}icon_1729960681.svg`,
        url: `${siteUrl}/tools/divorcer`,
        text: 'Divorcer',
    },
];
