import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { useModal } from '../context/AccountModalContext';

const useAuthCheck = () => {
    const { isAuthenticated, user } = useAuth0();
    const { toggleModalAccount } = useModal();

    const checkAuthentication = useCallback(() => {
        if (!isAuthenticated || !user) {
            toggleModalAccount();
            return false; // Early return if user is not authenticated
        }
        return true; // Return true if user is authenticated
    }, [isAuthenticated, user, toggleModalAccount]);

    return checkAuthentication;
};

export default useAuthCheck;
