import {
    Box,
    Link,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { checkEnv } from '../utils/Utils';
import ReturnBack from '../components/reusable/accordions/ReturnBack';
import { useLocation } from 'react-router-dom';
import { config } from '../config/config';

const tableData = [
    {
        identifier: 'A. Identifiers',
        description:
            'Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name.',
        collected: 'NO',
    },
    {
        identifier:
            'B. Personal information categories listed in the California Customer Records statute',
        description: '', // Add examples for this category if available
        collected: '', // Add collected status if available
    },
    {
        identifier:
            'C. Protected classification characteristics under California or federal law',
        description: 'Gender and date of birth',
        collected: 'NO',
    },
    {
        identifier: 'D. Commercial information',
        description:
            'Transaction information, purchase history, financial details, and payment information',
        collected: 'NO',
    },
    {
        identifier: 'E. Biometric information',
        description: 'Fingerprints and voiceprints',
        collected: 'NO',
    },
    {
        identifier: 'F. Internet or other similar network activity',
        description:
            'Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements',
        collected: 'NO',
    },
    {
        identifier: 'G. Geolocation data',
        description: 'Device location',
        collected: 'NO',
    },
    {
        identifier:
            'H. Audio, electronic, visual, thermal, olfactory, or similar information',
        description:
            'Images and audio, video or call recordings created in connection with our business activities',
        collected: 'NO',
    },
    {
        identifier: 'I. Professional or employment-related information',
        description:
            'Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us',
        collected: 'NO',
    },
    {
        identifier: 'J. Education Information',
        description: 'Student records and directory information',
        collected: 'NO',
    },
    {
        identifier: 'K. Inferences drawn from other personal information',
        description:
            'Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics',
        collected: 'NO',
    },
];

const PrivacyPolicy = () => {
    const location = useLocation();
    const isProduction = checkEnv();
    const { siteUrl, canonicalUrl } = config;

    return (
        <Box className="page-content ">
            <Box>
                <Helmet>
                    <title>Help | Toolkit.law</title>
                    <link
                        rel="canonical"
                        href={`${canonicalUrl}${location.pathname}`}
                    />
                    {isProduction && (
                        <>
                            <meta
                                name="description"
                                content=" Toolkit.law Policies, Legal Policy Guidelines Toolkit.law, Toolkit.law Terms and Conditions, Privacy Policy Toolkit.law, Toolkit.law User Policy, Toolkit.law Data Protection, Compliance Policies Toolkit.law, Toolkit.law User Agreements, Toolkit.law Legal Disclaimers, Toolkit.law Refund Policy, Toolkit.law Terms of Service, Policy Updates Toolkit.law, Toolkit.law Usage Policies, Toolkit.law Intellectual Property Policy, Toolkit.law Security Policy, Toolkit.law Acceptable Use Policy, Toolkit.law Customer Obligations, Toolkit.law Service Policies, Toolkit.law Policy Enforcement, Toolkit.law Dispute Resolution Policy, Toolkit.law User Rights, Toolkit.law Policy Overview, Toolkit.law Content Policy, Toolkit.law Third-Party Services Policy, Toolkit.law User Consent Policy, Toolkit.law Policy Revisions, Toolkit.law Legal Compliance, Toolkit.law Ethical Guidelines, Toolkit.law External Policy Links, Toolkit.law User Feedback Policy, Toolkit.law Communication Policy, Toolkit.law Licensing Policy, Toolkit.law Cookie Policy, Toolkit.law Data Retention Policy, Toolkit.law Contact for Policy Questions, Toolkit.law Policy Transparency, Toolkit.law User Safety Policy, Toolkit.law Service Level Agreement (SLA), Toolkit.law Risk Management Policy, Toolkit.law Best Practices Policy, Privacy Policy and Terms of Use of Toolkit.law, Privacy Policy of Toolkit.law, Privacy Notice for Toolkit.law, CCPA Privacy Notice for Toolkit.law"
                            />
                            <meta
                                name="keywords"
                                content="Toolkit.law Policies, Legal Policy Guidelines Toolkit.law, Toolkit.law Terms and Conditions, Privacy Policy Toolkit.law, Toolkit.law User Policy, Toolkit.law Data Protection, Compliance Policies Toolkit.law, Toolkit.law User Agreements, Toolkit.law Legal Disclaimers, Toolkit.law Refund Policy, Toolkit.law Terms of Service, Policy Updates Toolkit.law, Toolkit.law Usage Policies, Toolkit.law Intellectual Property Policy, Toolkit.law Security Policy, Toolkit.law Acceptable Use Policy, Toolkit.law Customer Obligations, Toolkit.law Service Policies, Toolkit.law Policy Enforcement, Toolkit.law Dispute Resolution Policy, Toolkit.law User Rights, Toolkit.law Policy Overview, Toolkit.law Content Policy, Toolkit.law Third-Party Services Policy, Toolkit.law User Consent Policy, Toolkit.law Policy Revisions, Toolkit.law Legal Compliance, Toolkit.law Ethical Guidelines, Toolkit.law External Policy Links, Toolkit.law User Feedback Policy, Toolkit.law Communication Policy, Toolkit.law Licensing Policy, Toolkit.law Cookie Policy, Toolkit.law Data Retention Policy, Toolkit.law Contact for Policy Questions, Toolkit.law Policy Transparency, Toolkit.law User Safety Policy, Toolkit.law Service Level Agreement (SLA), Toolkit.law Risk Management Policy, Toolkit.law Best Practices Policy, Privacy Policy and Terms of Use of Toolkit.law, Privacy Policy of Toolkit.law, Privacy Notice for Toolkit.law, CCPA Privacy Notice for Toolkit.law"
                            />
                        </>
                    )}
                </Helmet>
                <Suspense fallback={<Box>Loading...</Box>}>
                    <ReturnBack pageTitle="Privacy Policy and Terms of Use" />
                </Suspense>
            </Box>
            <Box p={2} pt={0}>
                <Typography
                    variant="body1"
                    sx={{ color: 'rgb(89,89,89)', fontSize: '15px' }}
                    component="span"
                >
                    This privacy notice for Toolkit.law, LLC describes how and
                    why we might collect, store, use, and/or share
                    <Typography
                        variant="body1"
                        component="span"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {' "process" '}
                    </Typography>
                    your information when you use our services
                    <Typography
                        variant="body1"
                        component="span"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {`"Services"`}
                    </Typography>
                    , such as when you:
                </Typography>
                <List className="privacy-list">
                    <ListItem>
                        <ListItemText primary="Download and use" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Engage with us in other related ways, including any sales, marketing, or events" />
                    </ListItem>
                </List>
                <Box sx={{ lineHeight: 1.5 }}>
                    <Typography variant="body1" color="text.secondary">
                        <Typography
                            component="span"
                            fontWeight="bold"
                            color="text.secondary"
                        >
                            Questions or concerns?
                        </Typography>
                        Reading this privacy notice will help you understand
                        your privacy rights and choices. If you do not agree
                        with our policies and practices, please do not use our
                        Services. If you still have any questions or concerns,
                        please contact us at{' '}
                        <Link
                            href="mailto:toolkit@toolkit.law?Subject=Inquiry"
                            color="text.secondary"
                        >
                            toolkit@toolkit.law
                        </Link>
                        .
                    </Typography>

                    <Box sx={{ mb: 2 }} />

                    <Typography variant="h6">
                        <Typography component="span" fontWeight="bold">
                            SUMMARY OF KEY POINTS
                        </Typography>
                    </Typography>

                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        <Typography
                            component="span"
                            fontStyle="italic"
                            fontWeight="bold"
                        >
                            This summary provides key points from our privacy
                            notice, but you can find out more details about any
                            of these topics by clicking the link following each
                            key point or by using our table of contents below to
                            find the section you are looking for. You can also
                            click{' '}
                        </Typography>
                        <Link href="#toc" color="primary">
                            here
                        </Link>{' '}
                        <Typography
                            component="span"
                            fontStyle="italic"
                            fontWeight="bold"
                        >
                            to go directly to our table of contents.
                        </Typography>
                    </Typography>

                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">
                            What personal information do we process?
                        </Typography>{' '}
                        When you visit, use, or navigate our Services, we may
                        process personal information depending on how you
                        interact with Toolkit.law services, the choices you
                        make, and the products and features you use. Click{' '}
                        <Link href="#personalinfo" color="primary">
                            here
                        </Link>{' '}
                        to learn more.
                    </Typography>

                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">
                            Do we process any sensitive personal information?
                        </Typography>{' '}
                        We do not process sensitive personal information.
                    </Typography>

                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">
                            Do we receive any information from third parties?
                        </Typography>{' '}
                        We do not receive any information from third parties.
                    </Typography>

                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">
                            How do we process your information?
                        </Typography>{' '}
                        We process your information to provide, improve, and
                        administer our Services, communicate with you, for
                        security and fraud prevention, and to comply with law.
                        We may also process your information for other purposes
                        with your consent. We process your information only when
                        we have a valid legal reason to do so. Click{' '}
                        <Link href="#infouse" color="primary">
                            here
                        </Link>{' '}
                        to learn more.
                    </Typography>

                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">
                            In what situations and with which parties do we
                            share personal information?
                        </Typography>{' '}
                        We may share information in specific situations and with
                        specific third parties. Click{' '}
                        <Link href="#whoshare" color="primary">
                            here
                        </Link>{' '}
                        to learn more.
                    </Typography>

                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">
                            How do we keep your information safe?
                        </Typography>{' '}
                        We have organizational and technical processes and
                        procedures in place to protect your personal
                        information. However, no electronic transmission over
                        the internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security and improperly collect, access, steal, or
                        modify your information. Click{' '}
                        <Link href="#infosafe" color="primary">
                            here
                        </Link>{' '}
                        to learn more.
                    </Typography>

                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">
                            What are your rights?
                        </Typography>{' '}
                        Depending on where you are located geographically, the
                        applicable privacy law may mean you have certain rights
                        regarding your personal information. Click{' '}
                        <Link href="#privacyrights" color="primary">
                            here
                        </Link>{' '}
                        to learn more.
                    </Typography>

                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">
                            How do you exercise your rights?
                        </Typography>{' '}
                        The easiest way to exercise your rights is by filling
                        out our data subject request form available{' '}
                        <Link
                            href="https://app.termly.io/notify/5bdfd5c4-de93-4af8-adbd-17be59d7e90f"
                            color="primary"
                        >
                            here
                        </Link>
                        , or by contacting us. We will consider and act upon any
                        request in accordance with applicable data protection
                        laws. Want to learn more about what Toolkit.law does
                        with any information we collect? Click{' '}
                        <Link href="#toc" color="primary">
                            here
                        </Link>{' '}
                        to review the notice in full.
                    </Typography>

                    <Box sx={{ mb: 2 }} />
                </Box>
                <Box>
                    <Box sx={{ mb: 2 }} />
                    <Box id="toc">
                        <Typography component="span" fontWeight="bold">
                            TABLE OF CONTENTS
                        </Typography>
                    </Box>
                    <Box sx={{ mb: 2 }} />
                    <Link
                        href="#infocollect"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            1. WHAT INFORMATION DO WE COLLECT?
                        </Typography>
                    </Link>
                    <Link
                        href="#infouse"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            2. HOW DO WE PROCESS YOUR INFORMATION?
                        </Typography>
                    </Link>
                    <Link
                        href="#legalbases"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                            PERSONAL INFORMATION?
                        </Typography>
                    </Link>
                    <Link
                        href="#whoshare"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                            INFORMATION?
                        </Typography>
                    </Link>
                    <Link
                        href="#3pwebsites"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                        </Typography>
                    </Link>
                    <Link
                        href="#cookies"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            6. DO WE USE COOKIES AND OTHER TRACKING
                            TECHNOLOGIES?
                        </Typography>
                    </Link>
                    <Link
                        href="#sociallogins"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </Typography>
                    </Link>
                    <Link
                        href="#intltransfers"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            8. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                        </Typography>
                    </Link>
                    <Link
                        href="#inforetain"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            9. HOW LONG DO WE KEEP YOUR INFORMATION?
                        </Typography>
                    </Link>
                    <Link
                        href="#infosafe"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            10. HOW DO WE KEEP YOUR INFORMATION SAFE?
                        </Typography>
                    </Link>
                    <Link
                        href="#infominors"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            11. DO WE COLLECT INFORMATION FROM MINORS?
                        </Typography>
                    </Link>
                    <Link
                        href="#privacyrights"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            12. WHAT ARE YOUR PRIVACY RIGHTS?
                        </Typography>
                    </Link>
                    <Link
                        href="#DNT"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            13. CONTROLS FOR DO-NOT-TRACK FEATURES
                        </Typography>
                    </Link>
                    <Link
                        href="#caresidents"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                            RIGHTS?
                        </Typography>
                    </Link>
                    <Link
                        href="#policyupdates"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            15. DO WE MAKE UPDATES TO THIS NOTICE?
                        </Typography>
                    </Link>
                    <Link
                        href="#contact"
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                    >
                        <Typography>
                            16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </Typography>
                    </Link>
                    <Link href="#request" sx={{ color: 'rgb(89,89,89)' }}>
                        <Typography>
                            17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA
                            WE COLLECT FROM YOU?
                        </Typography>
                    </Link>
                    <Box sx={{ mb: 2 }} />
                    <Box id="infocollect">
                        <Typography component="span" fontWeight="bold">
                            1. WHAT INFORMATION DO WE COLLECT?
                        </Typography>
                    </Box>
                    <Box sx={{ mb: 2 }} />
                    <Box id="personalinfo">
                        <Typography component="span" fontWeight="bold">
                            Personal information you disclose to us
                        </Typography>
                    </Box>
                    <Box sx={{ mb: 2 }} />
                    <Typography
                        sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}
                        component="i"
                    >
                        <Typography
                            component="span"
                            fontWeight="bold"
                            color={'text.secondary'}
                            fontStyle={'italic'}
                        >
                            In Short:
                        </Typography>{' '}
                        We collect personal information that you provide to us.
                    </Typography>
                    <Box sx={{ mb: 2 }} />
                    <Typography sx={{ color: 'rgb(89,89,89)', fontSize: 15 }}>
                        We collect personal information that you voluntarily
                        provide to us when you register on the Services,&nbsp;
                        express an interest in obtaining information about us or
                        our products and Services, when you participate in
                        activities on the Services, or otherwise when you
                        contact us.
                    </Typography>
                    <Box sx={{ mb: 2 }} />
                    <Typography color={'text.secondary'}>
                        <Typography
                            component="span"
                            fontWeight="bold"
                            color={'text.secondary'}
                        >
                            Personal Information Provided by You
                        </Typography>
                        The personal information that we collect depends on the
                        context of your interactions with us and the Services,
                        the choices you make, and the products and features you
                        use. The personal information we collect may include the
                        following:
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                    >
                        Contact Preferences:
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                    >
                        <Typography component="span" fontWeight="bold">
                            Sensitive Information:
                        </Typography>{' '}
                        We do not process sensitive information.
                    </Typography>
                    <Box sx={{ mb: 2 }} />
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        gutterBottom
                    >
                        <Typography
                            component="span"
                            fontWeight="bold"
                            color={'text.secondary'}
                        >
                            Payment Data:
                        </Typography>{' '}
                        We may collect data necessary to process your payment if
                        you make purchases, such as your payment instrument
                        number (such as a credit card number), and the security
                        code associated with your payment instrument. All
                        payment data is stored by PayPal. You may find their
                        privacy notice link(s) here:
                        <Link
                            href={`${siteUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            https://www.toolkit.law/policy
                        </Link>
                        .
                    </Typography>
                    <Box sx={{ mb: 2 }} />
                    <Typography color="text.secondary">
                        <Typography
                            component="span"
                            fontWeight={'bold'}
                            color="text.secondary"
                        >
                            Social Media Login Data:
                        </Typography>{' '}
                        We may provide you with the option to register with us
                        using your existing social media account details, like
                        your Facebook, Twitter, or other social media account.
                        If you choose to register in this way, we will collect
                        the information described in the section called "HOW DO
                        WE HANDLE YOUR SOCIAL LOGINS?" below.
                    </Typography>
                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        <Typography component="span" fontWeight={'bold'}>
                            Application Data:
                        </Typography>{' '}
                        If you use our application(s), we also may collect the
                        following information if you choose to provide us with
                        access or permission:
                    </Typography>
                    <List className="privacy-list">
                        <ListItem>
                            <Typography variant="body1">
                                <Typography component="i">
                                    Geolocation Information:
                                </Typography>{' '}
                                We may request access or permission to track
                                location-based information from your mobile
                                device, either continuously or while you are
                                using our mobile application(s), to provide
                                certain location-based services. If you wish to
                                change our access or permissions, you may do so
                                in your device's settings.
                            </Typography>
                        </ListItem>
                        <Box sx={{ mb: 2 }} />

                        <ListItem>
                            <Typography variant="body1">
                                <Typography component="i">
                                    Mobile Device Access:
                                </Typography>{' '}
                                We may request access or permission to certain
                                features from your mobile device, including your
                                mobile device's
                            </Typography>
                        </ListItem>
                        <Box sx={{ mb: 2 }} />

                        <ListItem>
                            <Typography variant="body1">
                                <Typography component="i">
                                    Push Notifications:
                                </Typography>{' '}
                                We may request to send you push notifications
                                regarding your account or certain features of
                                the application(s). If you wish to opt out from
                                receiving these types of communications, you may
                                turn them off in your device's settings.
                            </Typography>
                        </ListItem>
                    </List>
                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1">
                        This information is primarily needed to maintain the
                        security and operation of our application(s), for
                        troubleshooting, and for our internal analytics and
                        reporting purposes.
                    </Typography>
                    <Box sx={{ mb: 3 }} />
                    <Typography variant="body1" color="text.secondary">
                        All personal information that you provide to us must be
                        true, complete, and accurate, and you must notify us of
                        any changes to such personal information.
                    </Typography>
                    <Box mb={2} />
                    <Typography component="span" fontWeight="bold">
                        Information automatically collected
                    </Typography>
                    <Box sx={{ mb: 2 }} />

                    <Box>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{ fontStyle: 'italic' }}
                        >
                            <Typography
                                component="span"
                                fontWeight="bold"
                                color="textSecondary"
                                fontStyle={'italic'}
                            >
                                In Short:{' '}
                            </Typography>
                            Some information — such as your Internet Protocol
                            (IP) address and/or browser and device
                            characteristics — is collected automatically when
                            you visit our Services.
                        </Typography>
                    </Box>
                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1" color="textSecondary">
                        We automatically collect certain information when you
                        visit, use, or navigate the Services. This information
                        does not reveal your specific identity (like your name
                        or contact information) but may include device and usage
                        information, such as your IP address, browser and device
                        characteristics, operating system, language preferences,
                        referring URLs, device name, country, location,
                        information about how and when you use our Services, and
                        other technical information. This information is
                        primarily needed to maintain the security and operation
                        of our Services, and for our internal analytics and
                        reporting purposes.
                    </Typography>
                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1" color="textSecondary">
                        Like many businesses, we also collect information
                        through cookies and similar technologies.
                    </Typography>
                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1" color="textSecondary">
                        The information we collect includes:
                    </Typography>
                    <List className="privacy-list">
                        <ListItem sx={{ lineHeight: 1.5 }}>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                component="span"
                                sx={{ fontStyle: 'italic' }}
                            >
                                Log and Usage Data:
                            </Typography>
                            {''}
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                component="span"
                            >
                                Log and usage data is service-related,
                                diagnostic, usage, and performance information
                                our servers automatically collect when you
                                access or use our Services and which we record
                                in log files. Depending on how you interact with
                                us, this log data may include your IP address,
                                device information, browser type, and settings
                                and information about your activity in the
                                Services (such as the date/time stamps
                                associated with your usage, pages and files
                                viewed, searches, and other actions you take
                                such as which features you use), device event
                                information (such as system activity, error
                                reports (sometimes called
                            </Typography>
                        </ListItem>
                        <Box sx={{ mb: 2 }} />

                        <ListItem sx={{ lineHeight: 1.5 }}>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                component="span"
                                sx={{ fontStyle: 'italic' }}
                            >
                                Device Data:
                            </Typography>{' '}
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                component="span"
                            >
                                We collect device data such as information about
                                your computer, phone, tablet, or other device
                                you use to access the Services. Depending on the
                                device used, this device data may include
                                information such as your IP address (or proxy
                                server), device and application identification
                                numbers, location, browser type, hardware model,
                                Internet service provider and/or mobile carrier,
                                operating system, and system configuration
                                information.
                            </Typography>
                        </ListItem>
                        <Box sx={{ mb: 2 }} />

                        <ListItem sx={{ lineHeight: 1.5 }}>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                component="span"
                                sx={{ fontStyle: 'italic' }}
                            >
                                Location Data:
                            </Typography>{' '}
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                component="span"
                            >
                                We collect location data such as information
                                about your device's location, which can be
                                either precise or imprecise. How much
                                information we collect depends on the type and
                                settings of the device you use to access the
                                Services. For example, we may use GPS and other
                                technologies to collect geolocation data that
                                tells us your current location (based on your IP
                                address). You can opt out of allowing us to
                                collect this information either by refusing
                                access to the information or by disabling your
                                Location setting on your device. However, if you
                                choose to opt out, you may not be able to use
                                certain aspects of the Services.
                            </Typography>
                        </ListItem>
                        <Box sx={{ mb: 2 }} />
                    </List>

                    <Typography component="span" fontWeight="bold" id="infouse">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                    </Typography>

                    <Box sx={{ mb: 2 }} />
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontStyle: 'italic' }}
                    >
                        <Typography
                            component="span"
                            fontWeight="bold"
                            color="textSecondary"
                            fontStyle={'italic'}
                        >
                            In Short:{' '}
                        </Typography>
                        We process your information to provide, improve, and
                        administer our Services, communicate with you, for
                        security and fraud prevention, and to comply with law.
                        We may also process your information for other purposes
                        with your consent.
                    </Typography>
                    <Box mb={2} />
                    <Typography
                        component="span"
                        fontWeight="bold"
                        color="textSecondary"
                    >
                        We process your personal information for a variety of
                        reasons, depending on how you interact with our
                        Services, including:
                    </Typography>
                    <List className="privacy-list">
                        <ListItem sx={{ lineHeight: 1.5 }}>
                            <Typography variant="body1" color="textSecondary">
                                <Typography
                                    component="span"
                                    fontWeight="bold"
                                    color="textSecondary"
                                >
                                    To facilitate account creation and
                                    authentication and otherwise manage user
                                    accounts.
                                </Typography>{' '}
                                We may process your information so you can
                                create and log in to your account, as well as
                                keep your account in working order.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ mb: 2 }}>
                            <Typography variant="body1">
                                <Typography component="span" fontWeight="bold">
                                    To facilitate account creation and
                                    authentication and otherwise manage user
                                    accounts.
                                </Typography>{' '}
                                We may process your information when necessary
                                to save or protect an individual’s vital
                                interest, such as to prevent harm.
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
                <Box id="legalbases">
                    <Typography component="span" fontWeight={'bold'}>
                        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                        INFORMATION?
                    </Typography>
                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1" gutterBottom>
                        <Typography
                            component="span"
                            fontWeight={'bold'}
                            color={'text.secondary'}
                            fontStyle={'italic'}
                        >
                            In Short:
                        </Typography>{' '}
                        We only process your personal information when we
                        believe it is necessary and we have a valid legal reason
                        (i.e., legal basis) to do so under applicable law, like
                        with your consent, to comply with laws, to provide you
                        with services to enter into or fulfill our contractual
                        obligations, to protect your rights, or to fulfill our
                        legitimate business interests.
                    </Typography>
                    <Box sx={{ mb: 2 }} />

                    <Typography
                        variant="body1"
                        gutterBottom
                        sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
                    >
                        If you are located in the EU or UK, this section applies
                        to you.
                    </Typography>
                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1" gutterBottom>
                        The General Data Protection Regulation (GDPR) and UK
                        GDPR require us to explain the valid legal bases we rely
                        on in order to process your personal information. As
                        such, we may rely on the following legal bases to
                        process your personal information:
                    </Typography>

                    <List className="privacy-list">
                        <ListItem>
                            <Typography
                                component="span"
                                sx={{ fontWeight: 'bold' }}
                            >
                                Consent:&nbsp;
                            </Typography>
                            We may process your information if you have given us
                            permission (i.e., consent) to use your personal
                            information for a specific purpose.
                        </ListItem>
                        <Box sx={{ mb: 2 }} />

                        <ListItem>
                            <Typography variant="body1">
                                <Typography
                                    component="span"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    Legal Obligations:&nbsp;
                                </Typography>
                                We may process your information where we believe
                                it is necessary for compliance with our legal
                                obligations, such as to cooperate with a law
                                enforcement body or regulatory agency, exercise
                                or defend our legal rights, or disclose your
                                information as evidence in litigation in which
                                we are involved.
                            </Typography>
                        </ListItem>
                        <Box sx={{ mb: 2 }} />

                        <ListItem>
                            <Typography
                                component="span"
                                sx={{ fontWeight: 'bold' }}
                            >
                                Vital Interests:&nbsp;
                            </Typography>
                            We may process your information where we believe it
                            is necessary to protect your vital interests or the
                            vital interests of a third party, such as situations
                            involving potential threats to the safety of any
                            person.
                        </ListItem>
                        <Box sx={{ mb: 2 }} />
                    </List>

                    <Typography
                        variant="body1"
                        gutterBottom
                        sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
                    >
                        If you are located in Canada, this section applies to
                        you.
                    </Typography>
                    <Box sx={{ mb: 2 }} />

                    <Typography variant="body1" gutterBottom>
                        We may process your information if you have given us
                        specific permission (i.e., express consent) to use your
                        personal information for a specific purpose, or in
                        situations where your permission can be inferred (i.e.,
                        implied consent). You can withdraw your consent at any
                        time. Click&nbsp;
                        <Link href="#withdrawconsent">here</Link>&nbsp;to learn
                        more.
                    </Typography>
                </Box>
                <Box sx={{ mb: 2 }} />

                <Typography variant="body1" sx={{ fontSize: 15 }}>
                    In some exceptional cases, we may be legally permitted under
                    applicable law to process your information without your
                    consent, including, for example:
                </Typography>
                <Box sx={{ mb: 2 }} />

                <List className="privacy-list" sx={{ mb: 2 }}>
                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            If collection is clearly in the interests of an
                            individual and consent cannot be obtained in a
                            timely way
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            For investigations and fraud detection and
                            prevention
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            For business transactions provided certain
                            conditions are met
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            If it is contained in a witness statement and the
                            collection is necessary to assess, process, or
                            settle an insurance claim
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            For identifying injured, ill, or deceased persons
                            and communicating with next of kin
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            If we have reasonable grounds to believe an
                            individual has been, is, or may be victim of
                            financial abuse
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            If it is reasonable to expect collection and use
                            with consent would compromise the availability or
                            the accuracy of the information and the collection
                            is reasonable for purposes related to investigating
                            a breach of an agreement or a contravention of the
                            laws of Canada or a province
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            If disclosure is required to comply with a subpoena,
                            warrant, court order, or rules of the court relating
                            to the production of records
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            If it was produced by an individual in the course of
                            their employment, business, or profession and the
                            collection is consistent with the purposes for which
                            the information was produced
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            If the collection is solely for journalistic,
                            artistic, or literary purposes
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                            If the information is publicly available and is
                            specified by the regulations
                        </Typography>
                    </ListItem>
                </List>
                <Box sx={{ lineHeight: 1.5 }}>
                    {/* Section 4 Heading */}
                    <Box id="whoshare">
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ color: 'black', fontSize: '15px' }}
                        >
                            <Typography component="span" fontWeight="bold">
                                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                                INFORMATION?
                            </Typography>
                        </Typography>
                    </Box>
                    {/* Section 4 Body */}
                    <Box sx={{ mt: 1 }} id="whoshare">
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'rgb(89, 89, 89)',
                                fontSize: '15px',
                                fontStyle: 'italic',
                            }}
                        >
                            <Typography
                                component="span"
                                fontWeight="bold"
                                color={'text.secondary'}
                                fontStyle={'italic'}
                            >
                                In Short:
                            </Typography>{' '}
                            We may share information in specific situations
                            described in this section and/or with the following
                            third parties.
                        </Typography>

                        {/* List of sharing scenarios */}
                        <Typography
                            variant="body1"
                            sx={{ mt: 2, fontSize: '15px' }}
                        >
                            We may need to share your personal information in
                            the following situations:
                        </Typography>

                        <List className="privacy-list">
                            <ListItem>
                                <Typography variant="body1">
                                    <Typography
                                        component="span"
                                        fontWeight="bold"
                                        color={'text.secondary'}
                                    >
                                        Business Transfers.
                                    </Typography>{' '}
                                    We may share or transfer your information in
                                    connection with, or during negotiations of,
                                    any merger, sale of company assets,
                                    financing, or acquisition of all or a
                                    portion of our business to another company.
                                </Typography>
                            </ListItem>
                            <Box sx={{ mb: 2 }} />
                            <ListItem>
                                <Typography variant="body1">
                                    <Typography
                                        component="span"
                                        fontWeight="bold"
                                    >
                                        When we use Google Maps Platform APIs.
                                    </Typography>{' '}
                                    We may share your information with certain
                                    Google Maps Platform APIs.
                                </Typography>
                            </ListItem>
                        </List>
                    </Box>

                    {/* Section 5 Heading */}
                    <Typography
                        variant="h6"
                        component="div"
                        id="3pwebsites"
                        sx={{ mt: 4, color: 'black', fontSize: '15px' }}
                    >
                        <Typography component="span" fontWeight="bold">
                            5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                        </Typography>
                    </Typography>

                    {/* Section 5 Body */}
                    <Box sx={{ mt: 1 }}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'rgb(89, 89, 89)',
                                fontSize: '15px',
                                fontStyle: 'italic',
                            }}
                        >
                            <Typography
                                component="span"
                                fontWeight="bold"
                                color={'text.secondary'}
                                fontStyle={'italic'}
                            >
                                In Short:
                            </Typography>{' '}
                            We are not responsible for the safety of any
                            information that you share with third parties that
                            we may link to or who advertise on our Services, but
                            are not affiliated with, our Services.
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{ mt: 2, fontSize: '15px' }}
                        >
                            The Services may link to third-party websites,
                            online services, or mobile applications and/or
                            contain advertisements from third parties that are
                            not affiliated with us and which may link to other
                            websites, services, or applications. Accordingly, we
                            do not make any guarantee regarding any such third
                            parties, and we will not be liable for any loss or
                            damage caused by the use of such third-party
                            websites, services, or applications. The inclusion
                            of a link towards a third-party website, service, or
                            application does not imply an endorsement by us. We
                            cannot guarantee the safety and privacy of data you
                            provide to any third parties. Any data collected by
                            third parties is not covered by this privacy notice.
                            We are not responsible for the content or privacy
                            and security practices and policies of any third
                            parties, including other websites, services, or
                            applications that may be linked to or from the
                            Services. You should review the policies of such
                            third parties and contact them directly to respond
                            to your questions.
                        </Typography>
                    </Box>

                    {/* Section 6 Heading */}
                    <Typography
                        variant="h6"
                        component="div"
                        id="cookies"
                        sx={{ mt: 4, color: 'black', fontSize: '15px' }}
                    >
                        <Typography component="span" fontWeight="bold">
                            6. DO WE USE COOKIES AND OTHER TRACKING
                            TECHNOLOGIES?
                        </Typography>
                    </Typography>

                    {/* Section 6 Body */}
                    <Box sx={{ mt: 1 }}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'rgb(89, 89, 89)',
                                fontSize: '15px',
                                fontStyle: 'italic',
                            }}
                        >
                            <Typography
                                component="span"
                                fontWeight="bold"
                                color={'text.secondary'}
                                fontStyle={'italic'}
                            >
                                In Short:
                            </Typography>{' '}
                            We may use cookies and other tracking technologies
                            to collect and store your information.
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{ mt: 2, fontSize: '15px' }}
                        >
                            We may use cookies and similar tracking technologies
                            (like web beacons and pixels) to access or store
                            information. Specific information about how we use
                            such technologies and how you can refuse certain
                            cookies is set out in our Cookie Notice.
                        </Typography>
                    </Box>

                    {/* Section 7 Heading */}
                    <Typography
                        variant="h6"
                        component="div"
                        id="sociallogins"
                        sx={{ mt: 4, color: 'black', fontSize: '15px' }}
                    >
                        <Typography component="span" fontWeight="bold">
                            7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </Typography>
                    </Typography>

                    {/* Section 7 Body */}
                    <Box sx={{ mt: 1 }}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'rgb(89, 89, 89)',
                                fontSize: '15px',
                                fontStyle: 'italic',
                            }}
                        >
                            <Typography
                                component="span"
                                fontWeight="bold"
                                color={'text.secondary'}
                                fontStyle={'italic'}
                            >
                                In Short:
                            </Typography>{' '}
                            If you choose to register or log in to our services
                            using a social media account, we may have access to
                            certain information about you.
                        </Typography>
                        <Box sx={{ mb: 2 }} />
                        <Typography variant="body1">
                            Our Services offer you the ability to register and
                            log in using your third-party social media account
                            details (like your Facebook or Twitter logins).
                            Where you choose to do this, we will receive certain
                            profile information about you from your social media
                            provider. The profile information we receive may
                            vary depending on the social media provider
                            concerned, but will often include your name, email
                            address, friends list, and profile picture, as well
                            as other information you choose to make public on
                            such a social media platform.
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }}>
                        <Typography variant="body1">
                            We will use the information we receive only for the
                            purposes that are described in this privacy notice
                            or that are otherwise made clear to you on the
                            relevant Services. Please note that we do not
                            control, and are not responsible for, other uses of
                            your personal information by your third-party social
                            media provider. We recommend that you review their
                            privacy notice to understand how they collect, use,
                            and share your personal information, and how you can
                            set your privacy preferences on their sites and
                            apps.
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }} id="intltransfers">
                        <Typography component="span" fontWeight="bold">
                            8. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            fontSize={15}
                            fontStyle="italic"
                        >
                            <Typography
                                component="span"
                                fontWeight="bold"
                                color="textSecondary"
                                fontStyle={'italic'}
                            >
                                In Short:
                            </Typography>{' '}
                            We may transfer, store, and process your information
                            in countries other than your own.
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }}>
                        <Typography
                            variant="body1"
                            fontSize={15}
                            color="textSecondary"
                        >
                            Our servers are located in [server location]. If you
                            are accessing our Services from outside [your
                            country], please be aware that your information may
                            be transferred to, stored, and processed by us in
                            our facilities and by those third parties with whom
                            we may share your personal information (see{' '}
                            <Link href="#whoshare">
                                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                                INFORMATION?
                            </Link>{' '}
                            above), in and other countries.
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            fontSize={15}
                        >
                            If you are a resident in the European Economic Area
                            (EEA) or United Kingdom (UK), then these countries
                            may not necessarily have data protection laws or
                            other similar laws as comprehensive as those in your
                            country. However, we will take all necessary
                            measures to protect your personal information in
                            accordance with this privacy notice and applicable
                            law.
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }} id="inforetain">
                        <Typography component="span" fontWeight={'bold'}>
                            9. HOW LONG DO WE KEEP YOUR INFORMATION?
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            fontSize={15}
                            fontStyle="italic"
                        >
                            <Typography
                                component="span"
                                fontWeight={'bold'}
                                color="textSecondary"
                                fontStyle={'italic'}
                            >
                                In Short:&nbsp;
                            </Typography>
                            We keep your information for as long as necessary to
                            fulfill the purposes outlined in this privacy notice
                            unless otherwise required by law.
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            fontSize={15}
                        >
                            We will only keep your personal information for as
                            long as it is necessary for the purposes set out in
                            this privacy notice, unless a longer retention
                            period is required or permitted by law (such as tax,
                            accounting, or other legal requirements). No purpose
                            in this notice will require us keeping your personal
                            information for longer than the period of time in
                            which users have an account with us.
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            fontSize={15}
                        >
                            When we have no ongoing legitimate business need to
                            process your personal information, we will either
                            delete or anonymize such information, or, if this is
                            not possible (for example, because your personal
                            information has been stored in backup archives),
                            then we will securely store your personal
                            information and isolate it from any further
                            processing until deletion is possible.
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }} id="infosafe">
                        <Typography component="span" fontWeight={'bold'}>
                            10. HOW DO WE KEEP YOUR INFORMATION SAFE?
                        </Typography>
                    </Box>

                    <Box my={2} />

                    <Box sx={{ lineHeight: 1.5 }}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            fontSize={15}
                            fontStyle="italic"
                        >
                            <Typography
                                component="span"
                                fontWeight={'bold'}
                                color="textSecondary"
                                fontStyle="italic"
                            >
                                In Short:
                            </Typography>{' '}
                            We aim to protect your personal information through
                            a system of organizational and technical security
                            measures.
                        </Typography>
                    </Box>
                    <Box my={2} />
                </Box>
                <Box lineHeight={1.5}>
                    <Typography
                        variant="body1"
                        sx={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}
                    >
                        We have implemented appropriate and reasonable technical
                        and organizational security measures designed to protect
                        the security of any personal information we process.
                        However, despite our safeguards and efforts to secure
                        your information, no electronic transmission over the
                        Internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security and improperly collect, access, steal, or
                        modify your information. Although we will do our best to
                        protect your personal information, transmission of
                        personal information to and from our Services is at your
                        own risk. You should only access the Services within a
                        secure environment.
                    </Typography>
                </Box>

                <Box lineHeight={1.5}>&nbsp;</Box>

                <Box lineHeight={1.5} id="infominors">
                    <Typography component="span" fontWeight={'bold'}>
                        11. DO WE COLLECT INFORMATION FROM MINORS?
                    </Typography>
                </Box>
                <Box my={2} />

                <Box lineHeight={1.5}>
                    <Typography
                        variant="body1"
                        sx={{ color: 'rgb(89, 89, 89)', fontStyle: 'italic' }}
                    >
                        <Typography
                            component="span"
                            fontWeight="bold"
                            color={'text.secondary'}
                            fontStyle="italic"
                        >
                            In Short:
                        </Typography>
                        &nbsp;We do not knowingly collect data from or market to
                        children under 18 years of age.
                    </Typography>
                </Box>

                <Box my={2} />

                <Box lineHeight={1.5}>
                    <Typography
                        variant="body1"
                        sx={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}
                    >
                        We do not knowingly solicit data from or market to
                        children under 18 years of age. By using the Services,
                        you represent that you are at least 18 or that you are
                        the parent or guardian of such a minor and consent to
                        such minor dependent’s use of the Services. If we learn
                        that personal information from users less than 18 years
                        of age has been collected, we will deactivate the
                        account and take reasonable measures to promptly delete
                        such data from our records. If you become aware of any
                        data we may have collected from children under age 18,
                        please contact us at
                        <Typography component="span" fontWeight="bold">
                            __________
                        </Typography>
                        .
                    </Typography>
                </Box>

                <Box lineHeight={1.5}>&nbsp;</Box>

                <Box lineHeight={1.5} id="privacyrights">
                    <Typography component="span" fontWeight={'bold'}>
                        12. WHAT ARE YOUR PRIVACY RIGHTS?
                    </Typography>
                </Box>

                <Box my={2} />

                <Box>
                    <Typography
                        variant="body1"
                        component="i"
                        color={'text.secondary'}
                    >
                        <Typography
                            component="span"
                            fontWeight="bold"
                            color={'text.secondary'}
                            fontStyle={'italic'}
                        >
                            In Short:
                        </Typography>
                        &nbsp;In some regions, such as the European Economic
                        Area (EEA), United Kingdom (UK), and Canada, you have
                        rights that allow you greater access to and control over
                        your personal information. You may review, change, or
                        terminate your account at any time.
                    </Typography>
                </Box>

                <Box my={2} />

                <Box lineHeight={1.5}>
                    <Typography
                        variant="body1"
                        sx={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}
                    >
                        In some regions (like the EEA, UK, and Canada), you have
                        certain rights under applicable data protection laws.
                        These may include the right (i) to request access and
                        obtain a copy of your personal information, (ii) to
                        request rectification or erasure; (iii) to restrict the
                        processing of your personal information; and (iv) if
                        applicable, to data portability. In certain
                        circumstances, you may also have the right to object to
                        the processing of your personal information. You can
                        make such a request by contacting us by using the
                        contact details provided in the section "HOW CAN YOU
                        CONTACT US ABOUT THIS NOTICE?" below.
                    </Typography>
                </Box>

                <Box my={2} />

                <Box lineHeight={1.5}>
                    <Typography
                        variant="body1"
                        sx={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}
                    >
                        We will consider and act upon any request in accordance
                        with applicable data protection laws.
                    </Typography>
                </Box>

                <Box my={2} />

                <Box lineHeight={1.5}>
                    <Typography
                        variant="body1"
                        sx={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}
                    >
                        If you are located in the EEA or UK and you believe we
                        are unlawfully processing your personal information, you
                        also have the right to complain to your local data
                        protection supervisory authority. You can find their
                        contact details here:{' '}
                        <Link
                            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                        </Link>
                        .
                    </Typography>
                </Box>
                <Box my={2} />
                <Box sx={{ lineHeight: 1.5 }}>
                    {/* Contact Details for Data Protection Authorities */}
                    <Box mb={2}>
                        <Typography variant="body1" color="text.secondary">
                            If you are located in Switzerland, the contact
                            details for the data protection authorities are
                            available here:
                            <Link
                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                            >
                                https://www.edoeb.admin.ch/edoeb/en/home.html
                            </Link>
                            .
                        </Typography>
                    </Box>

                    {/* Withdrawing Your Consent */}
                    <Box mb={2} id="withdrawconsent">
                        <Typography variant="body1" color="text.secondary">
                            <Typography component="u" fontWeight="bold">
                                Withdrawing your consent:
                            </Typography>{' '}
                            If we are relying on your consent to process your
                            personal information, which may be express and/or
                            implied consent depending on the applicable law, you
                            have the right to withdraw your consent at any time.
                            You can withdraw your consent at any time by
                            contacting us using the contact details provided in
                            the section
                            <Link href="#contact" color="inherit">
                                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                            </Link>{' '}
                            below.
                        </Typography>
                    </Box>

                    {/* Lawfulness of Processing */}
                    <Box mb={2}>
                        <Typography variant="body1">
                            However, please note that this will not affect the
                            lawfulness of the processing before its withdrawal
                            nor, when applicable law allows, will it affect the
                            processing of your personal information conducted in
                            reliance on lawful processing grounds other than
                            consent.
                        </Typography>
                    </Box>

                    {/* Opting Out of Marketing Communications */}
                    <Box mb={2}>
                        <Typography variant="body1">
                            <Typography component={'u'} fontWeight={'bold'}>
                                Opting out of marketing and promotional
                                communications:
                            </Typography>{' '}
                            You can unsubscribe from our marketing and
                            promotional communications at any time by clicking
                            on the unsubscribe link in the emails that we send,
                            replying "STOP" or "UNSUBSCRIBE" to the SMS messages
                            that we send, or by contacting us using the details
                            provided in the section{' '}
                            <Link href="#contact">
                                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                            </Link>{' '}
                            below. You will then be removed from the marketing
                            lists. However, we may still communicate with you —
                            for example, to send you service-related messages
                            that are necessary for the administration and use of
                            your account, to respond to service requests, or for
                            other non-marketing purposes.
                        </Typography>
                    </Box>

                    {/* Account Information */}
                    <Box mb={2}>
                        <Typography component="span" fontWeight={'bold'}>
                            Account Information
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="body1">
                            If you would at any time like to review or change
                            the information in your account or terminate your
                            account, you can:
                        </Typography>
                        <List className="privacy-list">
                            <ListItem sx={{ lineHeight: 1.5 }}>
                                Log in to your account settings and update your
                                user account.
                            </ListItem>
                        </List>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="body1">
                            Upon your request to terminate your account, we will
                            deactivate or delete your account and information
                            from our active databases. However, we may retain
                            some information in our files to prevent fraud,
                            troubleshoot problems, assist with any
                            investigations, enforce our legal terms and/or
                            comply with applicable legal requirements.
                        </Typography>
                    </Box>

                    {/* Cookies and Similar Technologies */}
                    <Box mb={2}>
                        <Typography variant="body1" color="text.secondary">
                            <Typography component="u" fontWeight={'bold'}>
                                Cookies and similar technologies:
                            </Typography>{' '}
                            Most Web browsers are set to accept cookies by
                            default. If you prefer, you can usually choose to
                            set your browser to remove cookies and to reject
                            cookies. If you choose to remove cookies or reject
                            cookies, this could affect certain features or
                            services of our Services. To opt out of
                            interest-based advertising by advertisers on our
                            Services visit
                            <Link
                                href="http://www.aboutads.info/choices/"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                            >
                                http://www.aboutads.info/choices/
                            </Link>
                            .
                        </Typography>
                    </Box>

                    {/* Do Not Track Features */}
                    <Box mb={2} id="DNT">
                        <Typography component="span" fontWeight={'bold'}>
                            13. CONTROLS FOR DO-NOT-TRACK FEATURES
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="body1" color="text.secondary">
                            Most web browsers and some mobile operating systems
                            and mobile applications include a Do-Not-Track
                            ("DNT") feature or setting you can activate to
                            signal your privacy preference not to have data
                            about your online browsing activities monitored and
                            collected. At this stage no uniform technology
                            standard for recognizing and implementing DNT
                            signals has been finalized. As such, we do not
                            currently respond to DNT browser signals or any
                            other mechanism that automatically communicates your
                            choice not to be tracked online. If a standard for
                            online tracking is adopted that we must follow in
                            the future, we will inform you about that practice
                            in a revised version of this privacy notice.
                        </Typography>
                    </Box>
                </Box>
                <Box id="caresidents" mb={2}>
                    <Typography component="span" fontWeight={'bold'}>
                        14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                        RIGHTS?
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography
                        variant="body1"
                        sx={{ fontStyle: 'italic' }}
                        color={'text.secondary'}
                    >
                        <Typography
                            component="i"
                            fontWeight={'bold'}
                            color={'text.secondary'}
                            fontStyle={'italic'}
                        >
                            In Short:
                        </Typography>{' '}
                        Yes, if you are a resident of California, you are
                        granted specific rights regarding access to your
                        personal information.
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1" color="text.secondary">
                        California Civil Code Section 1798.83, also known as the{' '}
                        <Typography component="span" fontWeight={'bold'}>
                            "Shine The Light"
                        </Typography>{' '}
                        law, permits our users who are California residents to
                        request and obtain from us, once a year and free of
                        charge, information about categories of personal
                        information (if any) we disclosed to third parties for
                        direct marketing purposes and the names and addresses of
                        all third parties with which we shared personal
                        information in the immediately preceding calendar year.
                        If you are a California resident and would like to make
                        such a request, please submit your request in writing to
                        us using the contact information provided below.
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1" color="text.secondary">
                        If you are under 18 years of age, reside in California,
                        and have a registered account with Services, you have
                        the right to request removal of unwanted data that you
                        publicly post on the Services. To request removal of
                        such data, please contact us using the contact
                        information provided below and include the email address
                        associated with your account and a statement that you
                        reside in California. We will make sure the data is not
                        publicly displayed on the Services, but please be aware
                        that the data may not be completely or comprehensively
                        removed from all our systems (e.g., backups, etc.).
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography component="span" fontWeight={'bold'}>
                        CCPA Privacy Notice
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1" color="text.secondary">
                        The California Code of Regulations defines a "resident"
                        as:
                    </Typography>
                </Box>
                <Box ml={3}>
                    <Typography variant="body1" color="text.secondary">
                        (1) every individual who is in the State of California
                        for other than a temporary or transitory purpose and
                    </Typography>
                </Box>
                <Box ml={3}>
                    <Typography variant="body1" color="text.secondary">
                        (2) every individual who is domiciled in the State of
                        California who is outside the State of California for a
                        temporary or transitory purpose
                    </Typography>
                </Box>

                <Typography sx={{ color: 'rgb(89,89,89)', my: 2 }}>
                    All other individuals are defined as "non-residents."
                </Typography>

                <Typography sx={{ color: 'rgb(89,89,89)', my: 2 }}>
                    If this definition of "resident" applies to you, we must
                    adhere to certain rights and obligations regarding your
                    personal information.
                </Typography>

                <Typography sx={{ color: 'rgb(89,89,89)', my: 2 }}>
                    <Typography component="span" fontWeight="bold">
                        What categories of personal information do we collect?
                    </Typography>
                </Typography>

                <Typography sx={{ color: 'rgb(89,89,89)', my: 2 }}>
                    We have collected the following categories of personal
                    information in the past twelve (12) months:
                </Typography>

                <TableContainer component={'Paper'}>
                    <Table sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ border: '1px solid black' }}>
                                    <Typography
                                        component="span"
                                        fontWeight="bold"
                                    >
                                        Category
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid black' }}>
                                    <Typography
                                        component="span"
                                        fontWeight="bold"
                                    >
                                        Examples
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: '1px solid black',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography
                                        component="span"
                                        fontWeight="bold"
                                    >
                                        Collected
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{ border: '1px solid black' }}
                                    >
                                        <Typography
                                            sx={{
                                                color: 'rgb(89,89,89)',
                                                fontSize: 15,
                                            }}
                                        >
                                            {row.identifier}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ border: '1px solid black' }}
                                    >
                                        <Typography
                                            sx={{
                                                color: 'rgb(89,89,89)',
                                                fontSize: 15,
                                            }}
                                        >
                                            {row.description}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: '1px solid black',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: 'rgb(89,89,89)',
                                                fontSize: 15,
                                            }}
                                        >
                                            {row.collected}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography variant="body1" color="textSecondary" my={2}>
                    We may also collect other personal information outside of
                    these categories through instances where you interact with
                    us in person, online, or by phone or mail in the context of:
                </Typography>

                <List className="privacy-list">
                    <ListItem>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            fontSize="15px"
                        >
                            Receiving help through our customer support
                            channels;
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            fontSize="15px"
                        >
                            Participation in customer surveys or contests; and
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            fontSize="15px"
                        >
                            Facilitation in the delivery of our Services and to
                            respond to your inquiries.
                        </Typography>
                    </ListItem>
                </List>

                <Typography component="span" fontWeight="bold">
                    How do we use and share your personal information?
                </Typography>

                <Typography variant="body1" color="textSecondary" my={2}>
                    More information about our data collection and sharing
                    practices can be found in this privacy notice.
                </Typography>

                <Typography
                    variant="body1"
                    color="textSecondary"
                    fontSize="15px"
                    sx={{ marginTop: '1rem' }}
                >
                    You may contact us or by referring to the contact details at
                    the bottom of this document.
                </Typography>

                <Typography
                    variant="body1"
                    color="textSecondary"
                    fontSize="15px"
                    sx={{ marginTop: '1rem', my: 2 }}
                >
                    If you are using an authorized agent to exercise your right
                    to opt out we may deny a request if the authorized agent
                    does not submit proof that they have been validly authorized
                    to act on your behalf.
                </Typography>

                <Typography fontWeight="bold" component="span">
                    Will your information be shared with anyone else?
                </Typography>
                <Box mb={2} />
                <Typography color="textSecondary">
                    We may disclose your personal information with our service
                    providers pursuant to a written contract between us and each
                    service provider. Each service provider is a for-profit
                    entity that processes the information on our behalf.
                </Typography>
                <Box mb={2} />
                <Typography color="textSecondary">
                    We may use your personal information for our own business
                    purposes, such as for undertaking internal research for
                    technological development and demonstration. This is not
                    considered to be "selling" of your personal information.
                </Typography>
                <Box mb={2} />
                <Typography color="textSecondary">
                    __________ has not disclosed or sold any personal
                    information to third parties for a business or commercial
                    purpose in the preceding twelve (12) months. __________ will
                    not sell personal information in the future belonging to
                    website visitors, users, and other consumers.
                </Typography>
                <Box mb={2} />
                <Typography fontWeight="bold" component="span">
                    Your rights with respect to your personal data
                </Typography>
                <Box mb={2} />
                <Typography component="u" color="textSecondary">
                    Right to request deletion of the data — Request to delete
                </Typography>
                <Box mb={2} />
                <Typography color="textSecondary">
                    You can ask for the deletion of your personal information.
                    If you ask us to delete your personal information, we will
                    respect your request and delete your personal information,
                    subject to certain exceptions provided by law, such as (but
                    not limited to) the exercise by another consumer of his or
                    her right to free speech, our compliance requirements
                    resulting from a legal obligation, or any processing that
                    may be required to protect against illegal activities.
                </Typography>
                <Box mb={2} />
                <Typography
                    variant="subtitle1"
                    component="u"
                    color="textSecondary"
                >
                    Right to be informed — Request to know
                </Typography>
                <Box mb={2} />
                <Typography color="textSecondary">
                    Depending on the circumstances, you have a right to know:
                </Typography>
                <List color="textSecondary" className="privacy-list">
                    <ListItem>
                        whether we collect and use your personal information;
                    </ListItem>
                    <ListItem>
                        the categories of personal information that we collect;
                    </ListItem>
                    <ListItem>
                        the purposes for which the collected personal
                        information is used;
                    </ListItem>
                    <ListItem>
                        whether we sell your personal information to third
                        parties;
                    </ListItem>
                    <ListItem>
                        the categories of personal information that we sold or
                        disclosed for a business purpose;
                    </ListItem>
                    <ListItem>
                        the categories of third parties to whom the personal
                        information was sold or disclosed for a business
                        purpose; and
                    </ListItem>
                    <ListItem>
                        the business or commercial purpose for collecting or
                        selling personal information.
                    </ListItem>
                </List>
                <Typography>
                    In accordance with applicable law, we are not obligated to
                    provide or delete consumer information that is de-identified
                    in response to a consumer request or to re-identify
                    individual data to verify a consumer request.
                </Typography>
                <Box mb={2} />
                <Typography
                    variant="subtitle1"
                    component="u"
                    my={2}
                    color="textSecondary"
                >
                    Right to Non-Discrimination for the Exercise of a Consumer’s
                    Privacy Rights
                </Typography>

                <Box mb={2}>
                    <Typography variant="body1" color="textSecondary">
                        We will not discriminate against you if you exercise
                        your privacy rights.
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography
                        variant="body1"
                        component="u"
                        color="textSecondary"
                    >
                        Verification process
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1" color="textSecondary">
                        Upon receiving your request, we will need to verify your
                        identity to determine you are the same person about whom
                        we have the information in our system. These
                        verification efforts require us to ask you to provide
                        information so that we can match it with information you
                        have previously provided us. For instance, depending on
                        the type of request you submit, we may ask you to
                        provide certain information so that we can match the
                        information you provide with the information we already
                        have on file, or we may contact you through a
                        communication method (e.g., phone or email) that you
                        have previously provided to us. We may also use other
                        verification methods as the circumstances dictate.
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1" color="textSecondary">
                        We will only use personal information provided in your
                        request to verify your identity or authority to make the
                        request. To the extent possible, we will avoid
                        requesting additional information from you for the
                        purposes of verification. However, if we cannot verify
                        your identity from the information already maintained by
                        us, we may request that you provide additional
                        information for the purposes of verifying your identity
                        and for security or fraud-prevention purposes. We will
                        delete such additionally provided information as soon as
                        we finish verifying you.
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography
                        variant="body1"
                        component="u"
                        color="textSecondary"
                    >
                        Other privacy rights
                    </Typography>
                </Box>
                <List className="privacy-list" color="textSecondary">
                    <ListItem>
                        <Typography variant="body1">
                            You may object to the processing of your personal
                            information.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            You may request correction of your personal data if
                            it is incorrect or no longer relevant, or ask to
                            restrict the processing of the information.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            You may request to opt out from future selling of
                            your personal information to third parties. Upon
                            receiving an opt-out request, we will act upon the
                            request as soon as feasibly possible, but no later
                            than fifteen (15) days from the date of the request
                            submission.
                        </Typography>
                    </ListItem>
                </List>
                <Box mb={2}>
                    <Typography variant="body1" color="textSecondary">
                        To exercise these rights, you can contact us or by
                        referring to the contact details at the bottom of this
                        document. If you have a complaint about how we handle
                        your data, we would like to hear from you.
                    </Typography>
                </Box>
                <Box mb={2} id="policyupdates">
                    <Typography component={'span'} fontWeight={'bold'}>
                        15: DO WE MAKE UPDATES TO THIS NOTICE?
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography
                        variant="body1"
                        component="i"
                        color={'textSecondary'}
                    >
                        <Typography
                            component={'span'}
                            fontWeight={'bold'}
                            color={'textSecondary'}
                            fontStyle={'italic'}
                        >
                            In Short:{' '}
                        </Typography>
                        Yes, we will update this notice as necessary to stay
                        compliant with relevant laws.
                    </Typography>
                </Box>

                <Typography variant="body1" color="textSecondary">
                    We may update this privacy notice from time to time. The
                    updated version will be indicated by an updated "Revised"
                    date and the updated version will be effective as soon as it
                    is accessible. If we make material changes to this privacy
                    notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a
                    notification. We encourage you to review this privacy notice
                    frequently to be informed of how we are protecting your
                    information.
                </Typography>

                <Box sx={{ marginTop: 2 }} id="contact">
                    <Typography component={'span'} fontWeight={'bold'}>
                        16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ marginTop: 1 }}
                    >
                        If you have questions or comments about this notice, you
                        may email us at __________ or by post to:
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ marginTop: 1 }}
                    >
                        __________
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        __________
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        __________
                    </Typography>
                </Box>

                <Box sx={{ marginTop: 2 }} id="request">
                    <Typography component={'span'} fontWeight={'bold'}>
                        17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                        COLLECT FROM YOU?
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ marginTop: 1 }}
                    >
                        Based on the applicable laws of your country, you may
                        have the right to request access to the personal
                        information we collect from you, change that
                        information, or delete it. To request to review, update,
                        or delete your personal information, please submit a
                        request form by clicking&nbsp;
                        <Link
                            href="https://app.termly.io/notify/5bdfd5c4-de93-4af8-adbd-17be59d7e90f"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="primary"
                        >
                            here
                        </Link>
                        .
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default PrivacyPolicy;
