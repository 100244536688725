import React, { useState } from 'react';
import { Email, Feedback, Link, Share } from '@mui/icons-material';
import ActionDropdown from '../reusable/page/ActionDropdown';
import {
    handleDefaultEmail,
    handleGenerateFeedback,
    handleGenerateLink,
} from '../../utils/Utils';
import SuitesDropdown from './SuitesDropdown';
import { Box } from '@mui/material';

const SuitesToolbar = ({ title = '' }) => {
    const [copyMessage, setCopyMessage] = useState(false);
    const [copiedAction, setCopiedAction] = useState('');

    const handleGetWeblink = () => {
        handleGenerateLink(title, setCopyMessage, false, true);
        setCopiedAction('Weblink');
        setTimeout(() => {
            setCopyMessage(false);
        }, 2000);
    };

    const shareOption = [
        {
            name: 'Email',
            onClick: () => handleDefaultEmail(title.toString(), true),
            icon: <Email />,
        },
        {
            name: 'Feedback',
            onClick: () => handleGenerateFeedback(title.toString(), true),
            icon: <Feedback />,
        },
        {
            name: 'Weblink',
            onClick: handleGetWeblink,
            icon: <Link />,
        },
    ];

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            xxs={12}
            mb={2}
        >
            <ActionDropdown
                icon={<Share />}
                actions={shareOption}
                onActionClick={(actionName) => {
                    const action = shareOption.find(
                        (action) => action.name === actionName,
                    );
                    if (action) action.onClick();
                }}
                showCopied={copyMessage && copiedAction === 'Weblink'}
                actionName={copiedAction}
                dropdownKey="share"
            />

            <SuitesDropdown />
        </Box>
    );
};

export default SuitesToolbar;
