import React, { lazy, Suspense } from 'react';

import { Box } from '@mui/material';
import HeaderSkeleton from '../../components/skeleton/blogs/HeaderSkeleton';
import FooterSkeleton from '../../components/skeleton/blogs/FooterSkeleton';
const Header = lazy(() => import('./includes/Header'));
const Footer = lazy(() => import('./includes/Footer'));

const BlogLayout = ({ children }) => {
    return (
        <>
            <Suspense fallback={<HeaderSkeleton />}>
                <Header />
            </Suspense>
            <Box component="main" sx={{ minHeight: 'calc(100vh - 40px)' }}>
                {children}
            </Box>
            <Suspense fallback={<FooterSkeleton />}>
                <Footer />
            </Suspense>
        </>
    );
};

export default BlogLayout;
