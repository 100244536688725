import { Box, Skeleton } from '@mui/material';
import React from 'react';

const FooterSkeleton = () => {
    const renderBoxes = (count, direction = 'row') => {
        return Array.from({ length: count }).map((_, index) => (
            <Skeleton
                key={index}
                variant="rectangular"
                width={25}
                height={25}
                sx={{
                    ml: direction === 'row' ? 1 : 0,
                    mt: direction === 'column' ? 1 : 0,
                }}
            />
        ));
    };
    return (
        <Box>
            {/* Desktop Skeleton Layout */}
            <Box
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    color: 'black',
                }}
            >
                {renderBoxes(3)}

                <Skeleton variant="text" width={200} height={24} />

                {renderBoxes(3)}
            </Box>

            {/* Mobile Skeleton Layout */}
            <Box
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: 'black',
                }}
            >
                {/* Skeleton for Social icons */}
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        mb: 1,
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }}
                >
                    {renderBoxes(6)}
                </Box>
                {/* Skeleton for Copyright Text */}
                <Skeleton variant="text" width={200} height={24} />
            </Box>
        </Box>
    );
};

export default FooterSkeleton;
