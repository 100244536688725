import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { getAuthData } from '../utils/auth';
import { userAxios } from '../utils/api';
import { logger } from '../utils/SiteHelpers';
import { config } from '../config/config';

const ProtectedRoute = ({ element, ...rest }) => {
    const { user } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const checkSubscriptionStatus = async () => {
            if (!user) {
                logger.log('User not authenticated');
                return; // Exit if there is no user
            }

            try {
                const { email, sub: auth0id } = user;
                const authData = await getAuthData(email, auth0id);
                const { accessToken, userId } = authData;

                if (!accessToken) {
                    throw new Error('No access token available.');
                }

                const apiUrl = `${config.subscriptionUrl}access`;
                const payload = { user_id: userId };
                const axiosConfig = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                };

                const response = await userAxios.post(
                    apiUrl,
                    payload,
                    axiosConfig,
                );

                // Check if the user is not paid and redirect to plan page
                if (response.data.isPaid === 0) {
                    return navigate('/settings/billing/plans');
                }
            } catch (error) {
                logger.error('Error verifying subscription:', error);
            }
        };

        checkSubscriptionStatus();
    }, [user]);

    return element; // Render the protected element if user is paid
};

export default ProtectedRoute;
