import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomLink = styled(({ colorType, ...props }) => (
    <Link {...props} />
))(({ theme, colorType }) => ({
    color:
        colorType === 'warning'
            ? theme.palette.primary.main
            : colorType === 'primary'
            ? theme.palette.blue.main
            : 'black',
    '&:hover': {
        color:
            colorType === 'warning'
                ? theme.palette.primary.main
                : colorType === 'primary'
                ? theme.palette.primary.main
                : 'black',
    },
}));
