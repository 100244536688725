import React, { useEffect, useState } from 'react';
import { MenuItem, IconButton, Typography, Menu, Tooltip } from '@mui/material';
import { capitalize } from '../../../utils/Utils';
import TooltipWrapper from '../TooltipWrapper';

const ActionDropdown = ({
    icon,
    actions,
    onActionClick,
    showCopied,
    actionName,
    dropdownKey,
    toggleClassName,
    buttonStyles,
    saveRef,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [copiedAction, setCopiedAction] = useState('');

    useEffect(() => {
        if (!showCopied) {
            setCopiedAction('');
            setDropdownOpen(null);
        } else {
            setCopiedAction(actionName);
            // Keeping the dropdown open if it's the 'share' action and `showCopied` is true
            if (dropdownKey !== 'share') {
                setDropdownOpen(null);
            }
        }
    }, [showCopied, actionName]);

    const handleSave = () => {
        if (saveRef?.current) {
            const dummyEvent = { preventDefault: () => {} };
            saveRef.current.handleFormSubmit(dummyEvent);
        }
    };
    const handleToggle = (event) => {
        handleSave();
        // Toggle the dropdown menu
        setDropdownOpen(dropdownOpen ? null : event.currentTarget);
    };

    const handleItemClick = (actionName) => {
        onActionClick(actionName);
        if (actionName === 'copy' || actionName === 'weblink') {
            setCopiedAction(actionName);
            // Optionally keep the menu open or handle it differently for specific actions
        } else {
            setDropdownOpen(null); // Close the menu for other actions
        }
    };

    if (!icon) {
        return null;
    }

    return (
        <>
            <TooltipWrapper
                tooltip={dropdownKey === 'share' ? 'Share' : 'Download'}
            >
                <IconButton
                    onClick={handleToggle}
                    className={toggleClassName}
                    sx={{
                        color: 'black',
                        ...buttonStyles,
                    }}
                >
                    {icon}
                </IconButton>
            </TooltipWrapper>
            <Menu
                open={Boolean(dropdownOpen)} // This now correctly checks if there is an anchor element
                anchorEl={dropdownOpen} // Pass the element, not a boolean
                onClose={() => setDropdownOpen(null)}
            >
                {actions
                    ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                    ?.map((action, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => handleItemClick(action.name)}
                        >
                            {copiedAction === action.name ? (
                                <Typography variant="body2">Copied!</Typography>
                            ) : (
                                <>
                                    <span>{action.icon}</span>
                                    <Typography variant="body2" ml={1}>
                                        {dropdownKey === 'dropdown'
                                            ? action.name.toUpperCase()
                                            : capitalize(action.name)}
                                    </Typography>
                                </>
                            )}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
};

export default ActionDropdown;
