export function stripHtml(text) {
    let html = '';
    if (!text || text.length === 0) {
        return html;
    }

    if (Object.keys(text).length === 0) {
        return html;
    }

    if (typeof text === 'object' && !Array.isArray(text) && text !== null) {
        return html;
    }
    // console.log(text);
    return text.replace(/<[^>]*>/g, '');
}

export function convertToKey(value) {
    // Remove special characters using regex
    const key = value.replace(/[^a-zA-Z0-9]/g, '');

    return key;
}
