import React from 'react';
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { config } from '../../config/config';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({
    fileUrl,
    width,
    height,
    pageSize = 'PageFit',
    heading,
}) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const plugins = [defaultLayoutPluginInstance];
    const { publicPdfUrl } = config;

    // Get theme and set media query
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box
            sx={{
                width: isMobile ? '100%' : width ? width : '58%',
                height: isMobile ? '50vh' : height ? height : '100vh',
                overflow: 'hidden',
                display: 'block',
                margin: '0 auto',
            }}
        >
            {heading && (
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    align="center"
                    mb={2}
                >
                    {heading}
                </Typography>
            )}
            <Viewer
                theme="dark"
                fileUrl={`${publicPdfUrl}${fileUrl}`}
                plugins={plugins}
                defaultScale={
                    pageSize === 'PageFit'
                        ? SpecialZoomLevel.PageFit
                        : SpecialZoomLevel.pageSize
                }
            />
        </Box>
    );
};

export default PDFViewer;
