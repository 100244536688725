import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import PDFViewer from '../../../reusable/PDFViewer';
import MarketingVideo from '../../../../pages/about/MarketingVideo';
import { config } from '../../../../config/config';

const { imageUrl } = config;
const FlyerAndVideo = () => {
    // Get theme and set media query
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container>
            {/* Toolkit.law CLE Webinars Video */}
            <MarketingVideo
                videoUrl={'toolkitlaw-marketing-overview-flyer-video.mp4'}
                thumbnailUrl={`video-thumbnail-your-global-legal-partner.jpg`}
            />

            <PDFViewer
                fileUrl={`toolkitlaw-free-global-cle-webinars-packet.pdf`}
            />
        </Container>
    );
};

export default FlyerAndVideo;
