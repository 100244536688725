import axios from 'axios';
import { config } from '../config/config';

export const customLogin = async (email, oauth0Id) => {
    try {
        const response = await axios.post(`${config.apiUrl}custom-login`, {
            email: email,
            auth0_id: oauth0Id,
        });

        const data = response.data;
        console.log('Response:', data.data);

        if (response.status === 200) {
            // Successful login, store the token and its expiration time in sessionStorage
            const { token, expiry, userId, status, isPaid, trial, name } = data.data;
            const expiryDate = new Date(expiry);
            // Calculate the time difference in seconds between the current time and the expiry time.
            const timeDifferenceInSeconds =
                (expiryDate.getTime() - new Date().getTime()) / 1000;

            const expirationTime =
                new Date().getTime() + timeDifferenceInSeconds * 1000;
            sessionStorage.setItem('accessToken', token);
            sessionStorage.setItem(
                'tokenExpiration',
                expirationTime.toString(),
            );
            sessionStorage.setItem('userId', userId);

            // Set the cookie so that it should be accessible by brower extensions
            setCookie({
                token, 
                tokenExpiration: expiryDate.getTime(), 
                userId,
                userName: name,
                email,
                oauth0Id
            });

            if (isPaid === 0) {
                return (window.location.href = '/settings/billing/plans');
            } else {
                return { accessToken: token, userId: userId };
            }
        } else {
            // Login failed, handle error
            console.error('Login failed:', data.message);
            return null;
        }
    } catch (error) {
        console.error('Error occurred:', error);
    }
};

export const getAuthData = async (email, oauth0Id) => {
    // Check if access token and its expiration time exist in sessionStorage
    const existingToken = sessionStorage.getItem('accessToken');
    const expirationTime = sessionStorage.getItem('tokenExpiration');

    if (existingToken && expirationTime) {
        const currentTime = new Date().getTime();
        // If the token has not expired, return the existing access token
        if (parseInt(expirationTime) > currentTime) {
            const userId = sessionStorage.getItem('userId');
            return { accessToken: existingToken, userId: userId };
        }
    }

    try {
        // If the token has expired or is not present, get a new token using customLogin
        return await customLogin(email, oauth0Id);
    } catch (error) {
        console.error('Error occurred while getting access token:', error);
        throw error; // Rethrow the error for the calling code to handle
    }
};

const setCookie = ({ token, tokenExpiration, userId, userName, email, oauth0Id }) => {
    // Set the cookie with the access token and its expiration time
    document.cookie = `accessToken=${token}; max-age=${24 * 3600 * 60}; path=/; secure; samesite=None`;
    document.cookie = `userId=${userId}; max-age=${24 * 3600 * 60}; path=/; secure; samesite=None`;
    document.cookie = `tokenExpiration=${tokenExpiration}; max-age=${24 * 3600 * 60}; path=/; secure; samesite=None`;
    document.cookie = `userName=${userName}; max-age=${24 * 3600 * 60}; path=/; secure; samesite=None`;
    document.cookie = `email=${email}; max-age=${24 * 3600 * 60}; path=/; secure; samesite=None`;
    document.cookie = `oauth0Id=${oauth0Id}; max-age=${24 * 3600 * 60}; path=/; secure; samesite=None`;
}

export const clearCookie = () => {
    // Clear the cookie by setting the expiration time to a past date
    document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'tokenExpiration=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'userName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'oauth0Id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}