// Home.js
import React, { lazy, Suspense } from 'react';
import BlogLayout from './BlogLayout';
import { Route, Routes } from 'react-router-dom';
const BlogList = lazy(() => import('./BlogList'));
const BlogDetails = lazy(() => import('./BlogDetails'));

const BlogPage = () => {
    return (
        <BlogLayout>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Suspense>
                            <BlogList />
                        </Suspense>
                    }
                />
                <Route
                    path="/:slug"
                    element={
                        <Suspense>
                            <BlogDetails />
                        </Suspense>
                    }
                />
            </Routes>
        </BlogLayout>
    );
};

export default BlogPage;
